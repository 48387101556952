//todo 银行卡列表
export const bank = [
  [
    { label: "中国工商银行(ICBC)", value: "工商银行" },
    { label: "中国建设银行(CCB)", value: "建设银行" },
    { label: "中国农业银行(ABC)", value: "农业银行" },
    { label: "招商银行(CMB)", value: "招商银行" },
    { label: "中国银行(BOC)", value: "中国银行" },
    { label: "交通银行(BCM)", value: "交通银行" },
    { label: "中国邮政储蓄银行(PSBC)", value: "邮政储蓄" },
    { label: "中国民生银行(CMBC)", value: "民生银行" },
    { label: "上海浦东发展银行(SPDB)", value: "浦发银行" },
    { label: "兴业银行(CIB)", value: "兴业银行" },
    { label: "华夏银行(HB)", value: "华夏银行" },
    { label: "中国光大银行(CEB)", value: "光大银行" },
    // { label: "广东发展银行(GDB)", value: "广发银行" },
    { label: "中信银行(CITIC)", value: "中信银行" },
    { label: "平安银行(SZPAB)", value: "平安银行" },
    { label: "长沙银行(BCS)", value: "长沙银行" },
  ],
];

// //todo 获取银行图标
export const getBankIcon = (name) => {
  switch (name) {
    case "工商银行":
      return "ICBC";
    case "招商银行":
      return "CMB";
    case "建设银行":
      return "CCB";
    case "农业银行":
      return "ABC";
    case "中国银行":
      return "BOC";
    case "交通银行":
      return "COMM";
    case "民生银行":
      return "CMBC";
    case "浦发银行":
      return "SPDB";
    case "中信银行":
      return "CITIC";
    case "广发银行":
      return "GDB";
    case "平安银行":
      return "SZPAB";
    case "兴业银行":
      return "CIB";
    case "华夏银行":
      return "HXB";
    case "光大银行":
      return "CEB";
    case "邮政储蓄":
      return "PSBC";
    case "农商银行":
      return "RCB";
    case "长沙银行":
      return "BCS";
    case "商业银行":
      return "CB";
    case "支付宝":
      return "ALIPAY";
    default:
      return "";
  }
};

export const withdrawStatus = ["待处理", "处理成功", "处理失败", "已取消", "处理中", "汇款中", "已退款"];

//交易所對應logo
export const exInfo = [
  {
    img: "ex/USA",
    ex: "NYMEX",
  },
  {
    img: "ex/USA",
    ex: "COMEX",
  },
  {
    img: "ex/USA",
    ex: "CME",
  },
  {
    img: "ex/UK",
    ex: "EUREX",
  },
  {
    img: "ex/SG",
    ex: "SGX",
  },
  {
    img: "ex/HK",
    ex: "HKEX",
  },
  {
    img: "ex/CN",
    ex: "CEFEX",
  },
];

export const goodsImg = {
  ZF: "foregin/ZF",
  UZN: "foregin/ZN",
  ZB: "foregin/ZB",
  GBM: "foregin/GBM",
  GBL: "foregin/GBL",
  GBX: "foregin/GBX",
  USDJPY: "foregin/JP",
  EURUSD: "foregin/EUR",
  GBPUSD: "foregin/GBP",
  AUDUSD: "foregin/AUD",
  USDCAD: "foregin/CAD",
  USDCHF: "foregin/CHF",
  NZDUSD: "foregin/NZD",
  241: "stock/241",
  700: "stock/700",
  708: "stock/708",
  941: "stock/941",
  981: "stock/981",
  992: "stock/992",
  1024: "stock/1024",
  1211: "stock/1211",
  1357: "stock/1357",
  1810: "stock/1810",
  1833: "stock/1833",
  2269: "stock/2269",
  2331: "stock/2331",
  2338: "stock/2338",
  2382: "stock/2382",
  3690: "stock/3690",
  3888: "stock/3888",
  6185: "stock/6185",
  6862: "stock/6862",
  9618: "stock/9618",
  9626: "stock/9626",
  9633: "stock/9633",
  9888: "stock/9888",
  9988: "stock/9988",
  OI: "OI",
  CF: "CF",
  C: "C",
  RM: "RM",
  A: "A",
  I: "I",
  LH: "LH",
  AL: "AL",
  AG: "AG",
  AP: "AP",
  CL: "CL",
  CN: "CN",
  CU: "CU",
  DAX: "DAX",
  DOT: "DOT",
  DOTUSDT: "DOT",
  ES: "ES",
  FG: "FG",
  GC: "GC",
  HG: "HG",
  HSI: "HSI",
  IC: "IC",
  IH: "IH",
  IF: "IF",
  JD: "JD",
  M: "M",
  MDAX: "MDAX",
  MHI: "MHI",
  NG: "NG",
  NI: "NI",
  NK: "NK",
  NQ: "NQ",
  P: "P",
  TA: "TA",
  RB: "RB",
  RU: "RU",
  SC: "SC",
  SI: "SI",
  SR: "SR",
  YM: "YM",
  BU: "BU",
  USDT: "USDT",
  BCHUSDT: "BCH",
  BCH: "BCH",
  BSVUSDT: "BSV",
  BSV: "BSV",
  BTCUSDT: "BTC",
  DASHUSDT: "DASH",
  DASH: "DASH",
  EOSUSDT: "EOS",
  EOS: "EOS",
  ETHUSDT: "ETH",
  ETCUSDT: "ETC",
  ETC: "ETC",
  LINKUSDT: "LINK",
  LINK: "LINK",
  LTCUSDT: "LTC",
  LTC: "LTC",
  TRXUSDT: "TRX",
  XRPUSDT: "XRP",
  ATOMUSDT: "ATOM",
  ATOM: "ATOM",
  AAVEUSDT: "AAVE",
  AAVE: "AAVE",
  FILUSDT: "FIL",
  FIL: "FIL",
  XRP: "XRP",
  Y: "Y",
  TRX: "TRX",
  ETH: "ETH",
  BTC: "BTC",
  ADAUSDT: "ADA",
  ADA: "ADA",
  UNIUSDT: "UNI",
  UNI: "UNI",
  HTUSDT: "HT",
  HT: "HT",
  XMRUSDT: "XMR",
  XMR: "XMR",
  ZECUSDT: "ZEC",
  ZEC: "ZEC",
  BATUSDT: "BAT",
  BAT: "BAT",
  KNCUSDT: "KNC",
  KNC: "KNC",
  YFIUSDT: "YFI",
  YFI: "YFI",
  MKRUSDT: "MKR",
  MKR: "MKR",
  XTZUSDT: "XTZ",
  XTZ: "XTZ",
  OMGUSDT: "OMG",
  OMG: "OMG",
  CRVUSDT: "CRV",
  CRV: "CRV",
  SNXUSDT: "SNX",
  SNX: "SNX",
  RENUSDT: "REN",
  REN: "REN",
  SUSHIUSDT: "SUSHI",
  SUSHI: "SUSHI",
  XLMUSDT: "XLM",
  XLM: "XLM",
  ZRXUSDT: "ZRX",
  ZRX: "ZRX",
  DOGE: "DOGE",
  DOGEUSDT: "DOGE",
  SHIB: "SHIB",
  SHIBUSDT: "SHIB",
  AVAXUSDT: "AVAX",
  AXSUSDT: "AXS",
  GALAUSDT: "GALA",
  LINAUSDT: "LINA",
  LITUSDT: "LIT",
  LUNAUSDT: "LUNA",
  MANAUSDT: "MANA",
  MATICUSDT: "MATIC",
  NEARUSDT: "NEAR",
  SANDUSDT: "SAND",
  SOLUSDT: "SOL",
  UNFIUSDT: "UNFI",
  APEUSDT: "APE",
  DYDXUSDT: "DYDX",
  THETAUSDT: "THETA",
  CHZUSDT: "CHZ",
  ENJUSDT: "ENJ",
  WAVESUSDT: "WAVES",
  OPUSDT: "OP",
  APTUSDT: "APT",
  ICPUSDT: "ICP",
  FTMUSDT: "FTM",
  KLAYUSDT: "KLAY",
  GRTUSDT: "GRT",
  NEOUSDT: "NEO",
  ARBUSDT: "ARB",
  SUIUSDT: "SUI",
  PEPEUSDT: "PEPE",
  LDOUSDT: "LDO",
  ALGOUSDT: "ALGO",
  TRUMPUSDT: "TRUMP",
};

export const postList = [
  "qq.com",
  "163.com",
  "sina.com",
  "gmail.com",
  "icloud.com",
  "hotmail.com",
  "126.com",
  "yahoo.com",
  "outlook.com",
  "aol.com",
  "docomo.ne.jp",
  "me.com",
  "mac.com",
];

export function statusText(status) {
  switch (status) {
    case -1:
      return "待处理";
    case 0:
      return "待处理";
    case 1:
      return "处理成功";
    case 2:
      return "处理失败";
    case 3:
      return "已取消";
    case 4:
      return "处理中";
    case 5:
      return "汇款中";
    case 6:
      return "已退款";
    default:
      return "";
  }
}

export function paymentImg(name) {
  switch (name) {
    case "wechat":
      return "payment/wechat";
    case "aliPay":
      return "payment/alipay";
    // case "yun":
    // 	return "payment/yun";
    case "bank":
    case "other":
    default:
      return "payment/unit";
  }
}

//todo 根据不同银行显示不同颜色
export function color(name) {
  switch (name) {
    case "支付宝":
      return "alipay";
    case "工商银行":
      return "icbc";
    case "招商银行":
      return "cmb";
    case "建设银行":
      return "ccb";
    case "农业银行":
      return "abc";
    case "中国银行":
      return "boc";
    case "交通银行":
      return "comm";
    case "民生银行":
      return "cmbc";
    case "浦发银行":
      return "spcb";
    case "中信银行":
      return "citic";
    case "广发银行":
      return "gbd";
    case "平安银行":
      return "szpab";
    case "兴业银行":
      return "cib";
    case "华夏银行":
      return "hxb";
    case "光大银行":
      return "ceb";
    case "邮政储蓄":
      return "psbc";
    default:
      return "";
  }
}

// 舊框架的品類項目
export const oldGroupData = [
  {
    name: "商品期货",
    list: ["CL", "GC", "HG", "HSI"],
  },
  {
    name: "能源",
    list: ["I", "AL", "RU", "AG", "CU", "NI", "SC", "NG", "HG", "SI", "GC", "CL", "BU"],
  },
  {
    name: "股指",
    list: ["IF", "IC", "IH", "HSI", "MHI", "DAX", "MDAX", "NQ", "YM", "ES", "NK", "CN"],
  },
  {
    name: "主流",
    list: [
      "UNIUSDT",
      "XMRUSDT",
      "ZECUSDT",
      "DOTUSDT",
      "ATOMUSDT",
      "LINKUSDT",
      "BSVUSDT",
      "ETCUSDT",
      "TRXUSDT",
      "LTCUSDT",
      "EOSUSDT",
      "ADAUSDT",
      "XRPUSDT",
      "ETHUSDT",
      "BTCUSDT",
    ],
  },
  {
    name: "创新",
    list: [
      "SUSHIUSDT",
      "RENUSDT",
      "DOGEUSDT",
      "CRVUSDT",
      "OMGUSDT",
      "XTZUSDT",
      "MKRUSDT",
      "KNCUSDT",
      "BATUSDT",
      "ZRXUSDT",
      "DASHUSDT",
      "XLMUSDT",
      "AAVEUSDT",
      "FILUSDT",
      "SHIBUSDT",
      "APEUSDT",
      "DYDXUSDT",
      "THETAUSDT",
      "CHZUSDT",
      "ENJUSDT",
    ],
  },
  {
    name: "农业",
    list: ["AP", "A", "RM", "C", "CF", "OI", "LH", "P", "M", "Y", "JD", "SR"],
  },
  {
    name: "交易所",
    list: ["CL", "GC", "NQ", "DAX", "NK", "HSI", "IF"],
  },
  {
    name: "外汇",
    list: ["USDCHF", "USDCAD", "GBPUSD", "AUDUSD", "EURUSD", "USDJPY", "ZF", "UZN", "ZB", "GBM", "GBL", "GBX"],
  },
  {
    name: "股票",
    list: [
      "1833",
      "1810",
      "6185",
      "3690",
      "981",
      "1024",
      "1211",
      "6862",
      "700",
      "9988",
      "2382",
      "9618",
      "2331",
      "9633",
      "2338",
      "708",
      "3888",
      "1357",
      "9888",
      "2269",
      "992",
      "9626",
      "941",
      "241",
    ],
  },
  {
    name: "数字货币",
    list: ["BTCUSDT", "ETHUSDT", "XRPUSDT", "LTCUSDT"],
  },
  {
    name: "精选港股",
    list: ["9988", "9618", "1810", "3690"],
  },
  {
    name: "股指期货",
    list: ["HSI", "DAX", "NQ", "IF"],
  },
  {
    name: "外汇债券",
    list: ["EURUSD", "USDCAD", "AUDUSD", "USDJPY"],
  },
];
