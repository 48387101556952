let usd = 7;
export default {
  ES: {
    name: "标普500",
    code: "ES",
    exchange: "芝加哥商品交易所(CME)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x50美元",
    volatility: "指数点x50美元",
    volatilityIncome: "0.25(指数点) = 12.5美元",
    buyTimeAMWinter: "冬令时：</br> 上午07:00:00 - 次日05:15:00 </br> 次日05:30:00 - 次日05:50:00",
    buyTimeAM: "夏令时：</br> 上午06:00:00 - 次日04:15:00 </br> 次日04:30:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：</br> 上午07:00:00 - 次日05:15:00 <br/> 次日05:30:00 - 次日05:55:00",
    sellTimeAM: "夏令时：</br> 上午06:00:00 - 次日04:15:00 </br> 次日04:30:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所小型道指期货，最小波动5美元（相当于最小波动盈亏人民币${usd * 5}元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  YM: {
    name: "小道指",
    code: "YM",
    exchange: "芝加哥期货交易所 (CBOT)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x5美元",
    volatility: "指数点x5美元",
    volatilityIncome: "1(指数点) = 5美元",
    buyTimeAMWinter: "冬令时：</br> 上午07:00:00 - 次日05:15:00 </br> 次日05:30:00 - 次日05:50:00",
    buyTimeAM: "夏令时：</br> 上午06:00:00 - 次日04:15:00 </br> 次日04:30:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：</br> 上午07:00:00 - 次日05:15:00 <br/> 次日05:30:00 - 次日05:55:00",
    sellTimeAM: "夏令时：</br> 上午06:00:00 - 次日04:15:00 </br> 次日04:30:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所小型道指期货，最小波动5美元（相当于最小波动盈亏人民币${usd * 5}元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  NQ: {
    name: "小纳指",
    code: "NQ",
    exchange: "芝加哥商品交易所(CME)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x20美元",
    volatility: "指数点x20美元",
    volatilityIncome: "0.25(指数点) = 5美元",
    buyTimeAMWinter: "冬令时：</br> 上午07:00:00 - 次日05:15:00 <br/> 次日05:30:00 - 次日05:50:00",
    buyTimeAM: "夏令时：</br> 上午06:00:00 - 次日04:15:00 </br> 次日04:30:00 - 次日04:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter: "冬令时：</br> 上午07:00:00-次日05:15:00 <br/> 次日05:30:00 - 次日05:55:00",
    sellTimeAM: "夏令时：</br> 上午06:00:00 - 次日04:15:00 </br> 次日04:30:00 - 次日04:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所小纳指期货，最小波动5美元（相当于最小波动盈亏人民币${usd * 5}元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  HG: {
    name: "美铜",
    code: "HG",
    exchange: "纽约商品交易所(COMEX)",
    type: "金属期货",
    currency: "美元(USD)",
    unit: "25,000磅",
    volatility: "最新价x25,000磅",
    volatilityIncome: "0.0005(美元/磅) = 12.5美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所美铜期货，每手25000磅，最小波动12.5美元（相当于最小波动盈亏人民币${
      usd * 12.5
    }元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  MDAX: {
    name: "小德指",
    code: "MDAX",
    exchange: "欧洲期货交易所(EUREX)",
    type: "股指期货",
    currency: "欧元EUR",
    unit: "指数点x5欧元",
    volatility: "指数点x5欧元",
    volatilityIncome: "1(指数点) = 5欧元",
    buyTimeAMWinter: "冬令时：下午15:00:00 - 次日04:50:00",
    buyTimeAM: "夏令时：下午14:00:00 - 次日03:50:00",
    sellTimeAMWinter: "冬令时：下午15:00:00 - 次日04:55:00",
    sellTimeAM: "夏令时：下午14:00:00 - 次日03:55:00",
    clearTimeWinter: "冬令时：次日04:55:00",
    clearTime: "夏令时：次日03:55:00",
    rateDetail: "1欧元=8.0人民币",
    rate: "1欧元=8.0人民币（汇率波动较大时，将会进行调整）",
    introduce: "欧期所小型德指期货，每点5欧元，最小波动1点（相当于最小波动盈亏人民币40元），可买涨买跌。",
    isTimeWinter: "true",
  },
  CL: {
    name: "美原油",
    code: "CL",
    exchange: "纽约商品交易所 (NYMEX)",
    type: "能源化工",
    currency: "美元(USD)",
    unit: "1000桶",
    volatility: "最新价x1,000美元",
    volatilityIncome: "0.01(美元/桶) = 10美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所美原油期货，每手1000桶，最小波动10美元（相当于最小波动盈亏人民币${usd * 10}元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  GC: {
    name: "美黄金",
    code: "GC",
    exchange: "纽约商品交易所(COMEX)",
    type: "金属期货",
    currency: "美元(USD)",
    unit: "100盎司",
    volatility: "最新价x100美元",
    volatilityIncome: "0.1(美元/盎司) = 10美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所美黄金期货，每手100盎司，最小波动10美元（相当于最小波动盈亏人民币${usd * 10}元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  SI: {
    name: "美白银",
    code: "SI",
    exchange: "纽约商品交易所(COMEX)",
    type: "金属期货",
    currency: "美元(USD)",
    unit: "5000盎司",
    volatility: "最新价x5,000美元",
    volatilityIncome: "0.005(美元/盎司) = 25美元",
    buyTimeAMWinter: "冬令时:上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时: 上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时: 上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时: 上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所美白银期货，每手5000盎司，最小波动25美元（相当于最小波动盈亏人民币${usd * 25}元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  HSI: {
    name: "恒指",
    code: "HSI",
    exchange: "香港交易所(HKEX)",
    type: "股指期货",
    currency: "港币(HKD)",
    unit: "指数点x50港币",
    volatility: "指数点x50港币",
    volatilityIncome: "1(指数点) = 50港币",
    buyTimeAM: "上午 09:15:00 - 12:00:00",
    buyTimePM: "下午 13:00:00 - 16:20:00  </br> 夜间 17:15:00 - 02:50:00",
    sellTimeAM: "上午 09:15:00 - 12:00:00",
    sellTimePM: "下午 13:00:00 - 16:25:00 </br> 夜间 17:15:00 - 02:55:00",
    clearTime: "白天 16:25:00 夜间 02:55:00",
    rateDetail: "1港币=0.88人民币",
    rate: "1港币=0.88人民币（汇率波动较大时，将会进行调整）",
    introduce: "港交所恒指期货，每点50港元，最小波动1点（相当于最小波动盈亏人民币44元 ，可买涨买跌。",
    isTimeWinter: "false",
  },
  MHI: {
    name: "小恒指",
    code: "MHI",
    exchange: "香港交易所(HKEX)",
    type: "股指期货",
    currency: "港币(HKD)",
    unit: "指数点x10港元",
    volatility: "指数点x10港元",
    volatilityIncome: "1(指数点) = 10港元",
    buyTimeAM: "上午 09:15:00 - 12:00:00",
    buyTimePM: "下午 13:00:00 - 16:20:00 </br> 夜间 17:15:00 - 02:50:00",
    sellTimeAM: "上午 09:15:00 - 12:00:00",
    sellTimePM: "下午 13:00:00 - 16:25:00 </br> 夜间 17:15:00 - 02:55:00",
    clearTime: "白天 16:25:00 夜间 02:55:00",
    rateDetail: "1港币=0.88人民币",
    rate: "1港币=0.88人民币（汇率波动较大时，将会进行调整）",
    introduce: "港交所小恒指期货，每点10港元，最小波动1点（相当于最小波动盈亏人民币8.8元 ，可买涨买跌。",
    isTimeWinter: "false",
  },
  CN: {
    name: "富时A50",
    code: "CN",
    exchange: "新加坡交易所(SGX)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x1美元",
    volatility: "指数点x1美元",
    volatilityIncome: "1(指数点) = 1美元",
    buyTimeAM: "白天 09:00:00 - 16:20:00",
    buyTimePM: "夜间 17:00:00 - 05:05:00",
    sellTimeAM: "白天 09:00:00 - 16:25:00",
    sellTimePM: "夜间 17:00:00 - 05:10:00",
    clearTime: "白天 16:25:00 夜间 05:10:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `新交所富时A50期货，每点1美元，最小波动2.5点（相当于最小波动盈亏人民币${usd * 2.5}元） ，可买涨买跌。`,
    isTimeWinter: "false",
  },
  DAX: {
    name: "德指",
    code: "DAX",
    exchange: "欧洲期货交易所(EUREX)",
    type: "股指期货",
    currency: "欧元EUR",
    unit: "指数点x25欧元",
    volatility: "指数点x25欧元",
    volatilityIncome: "1(指数点)=25欧元",
    buyTimeAMWinter: "冬令时：下午15:00:00 - 次日04:50:00",
    buyTimeAM: "夏令时：下午14:00:00 - 次日03:50:00",
    sellTimeAMWinter: "冬令时：下午15:00:00 - 次日04:55:00",
    sellTimeAM: "夏令时：下午14:00:00 - 次日03:55:00",
    clearTimeWinter: "冬令时：次日04:55:00",
    clearTime: "夏令时：次日03:55:00",
    rateDetail: "1欧元=8.0人民币",
    rate: "1欧元=8.0人民币（汇率波动较大时，将会进行调整）",
    introduce: "欧期所德指期货，每点25欧元，最小波动0.5点（相当于最小波动盈亏人民币100元） ，可买涨买跌。",
    isTimeWinter: "true",
  },
  RB: {
    name: "螺纹钢",
    code: "RB",
    currency: "人民币",
    unit: "每手10吨",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所螺纹钢期货，每手10吨，最小波动1元（相当于最小波动盈亏10元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  PP: {
    name: "PP聚丙烯",
    code: "PP",
    currency: "人民币",
    unit: "每手5吨",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 5元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间22:55:00",
    rateDetail: "",
    rate: "",
    introduce: "大商所PP聚丙烯期货，每手5吨，最小波动1元（相当于最小波动盈亏5元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  NI: {
    name: "沪镍",
    code: "NI",
    exchange: "上海期货交易所(SHFE)",
    type: "金属期货",
    currency: "人民币(CNY)",
    unit: "每手1吨",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨)=10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 00:50:00",
    buyTimeNI: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 00:55:00",
    clearTime: "白天 14:55:00 夜间 00:55:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所沪镍期货，每手1吨，最小波动10元（相当于最小波动盈亏10元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  CU: {
    name: "沪铜",
    code: "CU",
    exchange: "上海期货交易所(SHFE)",
    type: "金属期货",
    currency: "人民币(CNY)",
    unit: "每手5吨",
    volatility: "最新价x50元",
    volatilityIncome: "10(元/吨) = 50元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 00:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 00:55:00",
    clearTime: "白天 14:55:00 夜间 00:55:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所沪铜期货，每手5吨，最小波动10元（相当于最小波动盈亏50元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  AG: {
    name: "沪银",
    code: "AG",
    exchange: "上海期货交易所(SHFE)",
    type: "金属期货",
    currency: "人民币(CNY)",
    unit: "每手15千克",
    volatility: "最新价x15元",
    volatilityIncome: "1(元/千克) = 15元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 02:20:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 02:25:00",
    clearTime: "白天 14:55:00 夜间 02:25:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所沪银期货，每手15千克，最小波动1元（相当于最小波动盈亏15元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  AU: {
    name: "沪金",
    code: "AU",
    currency: "人民币",
    unit: "每手1000克",
    volatility: "0.05元/克",
    volatilityIncome: "波动一次 = 50元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00",
    buyTimeNI: "夜间 21:00:00 - 02:20:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 02:25:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间 02:25:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所沪金期货，每手1000克，最小波动0.05元（相当于最小波动盈亏50元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  SR: {
    name: "白糖",
    code: "SR",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:50:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    rateDetail: "",
    rate: "",
    introduce: "郑商所白糖期货，每手10吨，最小波动1元（相当于最小波动盈亏10元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  RU: {
    name: "天然橡胶",
    code: "RU",
    exchange: "上海期货交易所(SHFE)",
    type: "能源化工",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "5(元/吨) = 50元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所天然橡胶期货，每手10吨，最小波动5元（相当于最小波动盈亏50元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  IC: {
    name: "中证500",
    code: "IC",
    exchange: "中国金融期货交易所(CFFEX)",
    type: "股指期货",
    currency: "人民币(CNY)",
    unit: "指数点x200元",
    volatility: "指数点x200元",
    volatilityIncome: "0.2(指数点) = 40元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:30:00 - 11:30:00",
    buyTimePM: "下午 13:00:00 - 14:50:00",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:30:00 - 11:30:00",
    sellTimePM: "下午 13:00:00 - 14:55:00",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00",
    rateDetail: "",
    rate: "",
    introduce: "中金所中证500股指期货，每点200元，最小波动0.2点（相当于最小波动盈亏40元） 可买涨买跌。",
    isTimeWinter: "false",
  },
  IH: {
    name: "上证50",
    code: "IH",
    exchange: "中国金融期货交易所(CFFEX)",
    type: "股指期货",
    currency: "人民币(CNY)",
    unit: "指数点x300元",
    volatility: "指数点x300元",
    volatilityIncome: "0.2(指数点) = 60元",
    buyTimeAM: "上午 09:30:00 - 11:30:00",
    buyTimePM: "下午 13:00:00 - 14:50:00",
    sellTimeAM: "上午 09:30:00 - 11:30:00",
    sellTimePM: "下午 13:00:00 - 14:55:00",
    clearTime: "白天 14:55:00",
    rateDetail: "",
    rate: "",
    introduce: "中金所上证50股指期货，每点300元，最小波动0.2点（相当于最小波动盈亏60元） 可买涨买跌。",
    isTimeWinter: "false",
  },
  IF: {
    name: "沪深300",
    code: "IF",
    exchange: "中国金融期货交易所(CFFEX)",
    type: "股指期货",
    currency: "人民币(CNY)",
    unit: "指数点x300元",
    volatility: "指数点x300元",
    volatilityIncome: "0.2(指数点) = 60元",
    buyTimeAM: "上午 09:30:00 - 11:30:00",
    buyTimePM: "下午 13:00:00 - 14:50:00",
    sellTimeAM: "上午 09:30:00 - 11:30:00",
    sellTimePM: "下午 13:00:00 - 14:55:00",
    clearTime: "白天 14:55:00",
    rateDetail: "",
    rate: "",
    introduce: "中金所沪深300股指期货，每点300元，最小波动0.2点（相当于最小波动盈亏60元） 可买涨买跌。",
    isTimeWinter: "false",
  },
  BU: {
    name: "沥青",
    code: "BU",
    currency: "人民币(CNY)",
    unit: "每手10吨",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所石油沥青期货，每手10吨，最小波动2元（相当于最小波动盈亏20元），可买涨买跌。",
    isTimeWinter: "false",
    exchange: "上海期货交易所(SHFE)",
    type: "能源化工",
  },
  HC: {
    name: "热卷",
    code: "HC",
    currency: "人民币(CNY)",
    unit: "每手10吨",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    rateDetail: "",
    rate: "",
    introduce: "上期所热轧卷板期货，每手10吨，最小波动2元（相当于最小波动盈亏20元），可买涨买跌。",
    isTimeWinter: "false",
  },
  MA: {
    name: "甲醇",
    code: "MA",
    currency: "人民币(CNY)",
    unit: "每手10吨",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    buyTimeNI: "夜间 21:00:00 - 23:20:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 23:25:00",
    clearTime: "白天 14:55:00 夜间 23:25:00",
    rateDetail: "",
    rate: "",
    introduce: "郑商所甲醇期货，每手10吨，最小波动1元（相当于最小波动盈亏10元），可买涨买跌。",
    isTimeWinter: "false",
  },
  FG: {
    name: "玻璃",
    code: "FG",
    exchange: "郑州商品交易所(ZCE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手20吨",
    volatility: "最新价x20元",
    volatilityIncome: "1(元/吨)=20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 23:20:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 23:25:00",
    clearTime: "白天 14:55:00 夜间 23:25:00",
    rateDetail: "",
    rate: "",
    introduce: "郑商所玻璃期货，每手20吨，最小波动1元（相当于最小波动盈亏20元），可买涨买跌。",
    isTimeWinter: "false",
  },
  SM: {
    name: "锰硅",
    code: "SM",
    currency: "人民币(CNY)",
    unit: "每手5吨",
    volatility: "2元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00 ",
    rateDetail: "",
    rate: "",
    introduce: "郑商所锰硅期货，每手5吨，最小波动1元（相当于最小波动盈亏10元），可买涨买跌。",
    isTimeWinter: "false",
  },
  NG: {
    name: "天然气",
    code: "NG",
    exchange: "纽约商品交易所 (NYMEX)",
    type: "能源化工",
    currency: "美元(USD)",
    volatilityIncome: "0.001(美元/mmBtu)=10美元",
    unit: "10000mmBtu",
    volatility: "最新价x10,000美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所天然气期货，每手10000mmBtu，最小波动10美元（相当于最小波动盈亏人民币${
      usd * 10
    }元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  QM: {
    name: "小原油",
    code: "QM",
    currency: "美元(USD)",
    unit: "每手500桶",
    volatility: "0.025美元/桶",
    volatilityIncome: "波动一次=12.5美元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午07:00:00 - 次日05:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午07:00:00 - 次日05:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "次日05:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所小原油期货，每手500桶，最小波动12.5美元（相当于最小波动盈亏人民币${
      usd * 12.5
    }元） ，可买涨买跌。`,
    isTimeWinter: "false",
  },
  RBB: {
    name: "汽油",
    code: "RBB",
    currency: "美元(USD)",
    unit: "每手42000美制加仑",
    volatility: "0.0001美元/加仑",
    volatilityIncome: "波动一次4.2美元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午07:00:00 - 次日05:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午07:00:00 - 次日05:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "次日05:55:00",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所汽油期货，每手42,000美制加仑，最小波动4.2美元（相当于最小波动盈亏人民币${
      usd * 4.2
    }元） ，可买涨买跌。`,
    isTimeWinter: "false",
  },
  SC: {
    name: "原油期货",
    code: "SC",
    exchange: "上海期货交易所(SHFE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "1000桶",
    volatility: "最新价x1000元",
    volatilityIncome: "0.1(元/桶) = 100元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br>上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br>夜间 21:00:00 - 02:20:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br>上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br>夜间 21:00:00 - 02:25:00",
    clearTime: "白天 14:55:00 夜间 02:25:00",
    rateDetail: "",
    rate: "",
    introduce: "上海国际能源交易中心，每手1000桶，最小波动0.1元（相当于最小波动盈亏100元)，可买涨买跌。",
    isTimeWinter: "false",
  },
  NK: {
    name: "日经指数",
    code: "NK",
    exchange: "新加坡交易所(SGX)",
    type: "股指期货",
    currency: "日元(JPY)",
    unit: "指数点x500日元",
    volatility: "指数点x500日元",
    volatilityIncome: "5(指数点) = 25,00日元",
    buyTimeAM: "上午 07:30:00 - 下午14:20:00",
    buyTimePM: "下午 14:55:00 - 夜间04:35:00",
    sellTimeAM: "上午 07:30:00 - 下午14:25:00",
    sellTimePM: "下午 14:55:00 - 夜间04:40:00",
    clearTime: "白天 14:25:00 夜间 04:40:00",
    rateDetail: "1日元=0.06元人民币",
    rate: "1日元=0.06元人民币（汇率波动较大时，将会进行调整）",
    introduce: "新交所日经指数期货，每点500日元，最小波动5个指数点（相当于最小波动盈亏人民币150元，可买涨买跌。",
    isTimeWinter: "false",
  },
  TA: {
    name: "PTA",
    code: "TA",
    exchange: "郑州商品交易所(ZCE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手5吨",
    volatility: "最新价x2.5元",
    volatilityIncome: "2(元/吨) = 2.5元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br/> 夜间 21:00:00 - 22:55:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br/> 夜间 21:30:00 - 22:55:00",
    clearTime: "下午 14:55:00 夜间 22:55:00",
  },
  I: {
    name: "铁矿",
    code: "I",
    exchange: "大连商品交易所(DCE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手100吨",
    volatility: "最新价x100元",
    volatilityIncome: "0.5(元/吨) = 50元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br/> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br/> 夜间 21:30:00 - 22:55:00",
    clearTime: "下午 14:55:00 夜间 22:55:00",
  },
  AL: {
    name: "沪铝",
    code: "AL",
    exchange: "上海期货交易所(SHFE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手5吨",
    volatility: "最新价x5元",
    volatilityIncome: "5(元/吨) = 25元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br/> 夜间 21:00:00 - 00:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br/> 夜间 21:30:00 - 00:55:00",
    clearTime: "下午 14:55:00 夜间 00:55:00",
  },
  P: {
    name: "棕榈油",
    code: "P",
    exchange: "大连商品交易所(DCE)",
    type: "能源化工",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "2(元/吨) = 20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  M: {
    name: "豆粕",
    code: "M",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  Y: {
    name: "豆油",
    code: "Y",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "2(指数点) = 20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  LH: {
    name: "生猪",
    code: "LH",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手16吨",
    currency: "人民币(CNY)",
    volatility: "最新价x16元",
    volatilityIncome: "5(元/吨)=80元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00",
  },
  JD: {
    name: "鸡蛋",
    code: "JD",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手5吨",
    currency: "人民币(CNY)",
    volatility: "最新价x20元",
    volatilityIncome: "1(元/500千克) = 20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00",
  },
  AP: {
    name: "苹果",
    code: "AP",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00",
  },
  OI: {
    name: "菜籽油",
    code: "OI",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  CF: {
    name: "郑棉",
    code: "CF",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手5吨",
    currency: "人民币(CNY)",
    volatility: "最新价x5元",
    volatilityIncome: "5(元/吨) = 25元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  C: {
    name: "玉米",
    code: "C",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  RM: {
    name: "菜粕",
    code: "RM",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  A: {
    name: "豆一",
    code: "A",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
  },
  TRUMPUSDT: {
    name: "TRUMP",
    code: "TRUMP/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    min: "50人民币",
    max: "50000人民币",
    lever: "10x~100x",
    buyTimeAM: "7*24小时",
    holdingTime: "最长60天",
    clearTime: "次日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    overnightCharge: "每日05:55:00",
    publicTime: "2025/01/18",
    publicTotal: "1,000,000,000",
    publicCurrent: "199,999,999",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://gettrumpmemes.com/",
    publicSearch: "https://solscan.io/",
    dec: "TRUMP 是特朗普的官方模因币。",
  },
  BTCUSDT: {
    name: "BTC",
    code: "BTC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    min: "50 CNY",
    max: "500,000 CNY",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    lever: "10X~100X",
    publicTime: "2008/11/01",
    publicTotal: "2100万",
    publicCurrent: "81.79%",
    publicPrice: "--",
    shu: "https://bitcoin.org/bitcoin.ptf",
    publicWeb: "https://bitcoin.org/en/",
    publicSearch: "https://blockchain.info/",
    dec: "\t比特币（Bitcoin，简称BTC）是目前使用最为广泛的一种数字货币，它诞生于2009年1月3日，是一种点对点（P2P）传输的数字加密货币，总量2100万枚。比特币网络每10分钟释放出一定数量币，预计在2140年达到极限。比特币被投资者称为“数字黄金”。比特币依据特定算法，通过大量的计算产生，不依靠特定货币机构发行，其使用整个P2P网络中众多节点构成的分布式数据库来确认并记录所有的交易行为，并使用密码学设计确保货币流通各个环节安全性，可确保无法通过大量制造比特币来人为操控币值。基于密码学的设计可以使比特币只能被真实拥有者转移、支付及兑现。同样确保了货币所有权与流通交易的匿名性。",
  },
  ETHUSDT: {
    name: "ETH",
    code: "ETH/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "10X~100X",
    publicTime: "2014/07/24",
    publicTotal: "9631.15万",
    publicCurrent: "100%",
    publicPrice: "¥1.89",
    shu: "https://github.com/ethereum/wiki/…",
    publicWeb: "https://www.ethereum.org",
    publicSearch: "https://etherscan.io",
    dec: "\t以太坊（Ethereum）是下一代密码学账本，可以支持众多的高级功能，包括用户发行货币，智能协议，去中心化的交易和设立去中心化自治组织(DAOs)或去中心化自治公司（DACs）。以太坊并不是把每一单个类型的功能作为特性来特别支持，相反，以太坊包括一个内置的图灵完备的脚本语言，允许通过被称为“合同”的机制来为自己想实现的特性写代码。一个合同就像一个自动的代理，每当接收到一笔交易，合同就会运行特定的一段代码，这段代码能修改合同内部的数据存储或者发送交易。",
  },
  ETCUSDT: {
    name: "ETC",
    code: "ETC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2015/11/01",
    publicTotal: "9833.66万",
    publicCurrent: "100%",
    publicPrice: "--",
    shu: "https://ethereumclassic.github.io/…",
    publicWeb: "https://ethereumclassic.github.io/",
    publicSearch: "https://ethereumclassic.github.io/",
    dec: "\tETC（Ethereum Classic）是以太坊在1,920,000个块后硬分叉出的分叉币种，功能和以太坊极为类似。ETC秉承去中心化理念，支持区块链保证的共识机制。ETC坚信，区块链一旦开始运行，它的发展方向就不被任何中心团队所左右，而是按照参与整个网络人员的共识和全网算力的共识所决定。",
  },
  XRPUSDT: {
    name: "XRP",
    code: "XRP/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2011/04/18",
    publicTotal: "10000000万",
    publicCurrent: "39.37%",
    publicPrice: "--",
    shu: "https://ripple.com/files/ripple_co…",
    publicWeb: "https://ripple.com",
    publicSearch: "https://ripple.com/graph",
    dec: "\t瑞波币（Ripple/XRP）提供一个无阻碍的利用区块链技术的全球支付网络，是世界上第一个开放的支付网络，通过加入Ripple正在成长的全球支付网络可以转账任意一种货币，包括美元、欧元、人民币、日元或者比特币，简便易行快捷，交易确认在几秒以内完成，交易费用几乎是零，没有所谓的跨行异地以及跨国支付费用。 Ripple是开放源码的点到点支付网络，它可以使任何机构或个人轻松、廉价并安全的把金钱转账到互联网上的任何机构或个人，无论在世界的哪个地方。Ripple是p2p软件，没有任何个人、公司，或政府操控，任何人可以创建一个ripple账户。",
  },
  BCHUSDT: {
    name: "BCH",
    code: "BCH/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017/08/01",
    publicTotal: "21000000",
    publicCurrent: "19,541,806",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://bch.info/en/",
    publicSearch: "https://blockchair.com/bitcoin-cash?from=bitcoin.com",
    dec: "比特币现金是原始比特币的硬分叉版本。就协议而言，他与比特币相似；工作证明SHA-256散列，21,000,000个供应，相同的封锁时间和奖励系统。但是，两个主要的区别是块大小的限制，截至8月份，比特币有1MB的块大小限制，而BCC/BCH则建议8MB块。此外，(BCC)/BCH将每6个区块调整一次难度，而不是像比特币的2016块。",
  },
  LTCUSDT: {
    name: "LTC",
    code: "LTC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2011/11/09",
    publicTotal: "8400万",
    publicCurrent: "68.89%",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://litecoin.com",
    publicSearch: "http://explorer.litecoin.net/chain/",
    dec: "\t莱特币诞生于2011年11月9日，被称为是“数字白银”。莱特币在技术上和比特币具有相同的实现原理。它是第一个基于Scrypt算法的网络数字货币，与比特币相比，莱特币拥有更快的交易确认时间，更高的网络交易容量和效率。莱特币现在拥有完整的产业链，充分的流动性，足以证明其是成熟、安全、稳定的商用金融系统。",
  },
  EOSUSDT: {
    name: "EOS",
    code: "EOS/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017/07/02",
    publicTotal: "10亿",
    publicCurrent: "96.06%",
    publicPrice: "￥7",
    shu: "https://github.com/EOSIO/Docume…",
    publicWeb: "https://eos.io",
    publicSearch: " https://etherscan.io/token/EOS",
    dec: "\tEOS (Enterprise Operation System)是由 Block.one公司主导开发的一种全新的基于区块链智能合约平台，旨在为高性能分布式应用提供底层区块链平台服务。EOS 项目的目标是实现一个类似操作系统的支撑分布式应用程序的区块链架构。该架构可以提供账户，身份认证，数据库，异步通信以及可在数以万计的 CPU/GPU群集上进行程序调度和并行运算。EOS最终可以支持每秒执行数百万个交易，同时普通用户执行智能合约无需支付使用费用。",
  },
  TRXUSDT: {
    name: "TRX",
    code: "TRX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017/09/14",
    publicTotal: "100亿",
    publicCurrent: "66.41%",
    publicPrice: "￥0.01",
    shu: "https://dn-peiwo-web.qbox.m…",
    publicWeb: " https://tron.network",
    publicSearch: "https://etherscan.io/address…",
    dec: "\t波场TRON是基于区块链的开源去中心化内容娱乐协议。它致力于利用区块链与分布式存储技术，构建一个全球范围内的自由内容娱乐体系，这个协议可以让每个用户自由发布，存储，拥有数据，并通过去中心化的自治形式，以数字资产发行，流通。交易方式决定内容的分发、订阅、推送，赋能内容创造者，形成去中心化的内容娱乐生态。",
  },
  DASHUSDT: {
    name: "DASH",
    code: "DASH/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2014/01/18",
    publicTotal: "18,900,000",
    publicCurrent: "8,850,289",
    publicPrice: "--",
    shu: " https://www.stellar.org/papers/…",
    publicWeb: "https://www.dash.org",
    publicSearch: "https://chainz.cryptoid.info/dash",
    dec:
      "\t达世币是一种可线上操作的数字货币，用户可以使用达世币进行安全的在线即时支付，商户则可以为店内添加由全球千万用户所建立的开源支付平台。\n" +
      "\n" +
      "\t达世币核心由独特的激励制P2P网络构成。 矿工们维护区块链安全得到奖励；而主节点持有者则是为用户验证交易、存储数据以及提供多种服务而获得奖励。\n" +
      "\n" +
      "\t主节点代表着新一层级的网络。 它们可组成高度安全的集群 – 仲裁链, 提供多种类的去中心化服务，如即时交易、匿名性、去中心化管理等等，同时它还可以防止低成本的网络攻击。\n" +
      "\n" +
      "\t得益于达世币的奖励机制，它的网络主节点自2014年发行以来已经增长到了4100个，这意味着达世币P2P网络已经成为全球最大的网络之一。\n" +
      "\n" +
      "\t更多的节点意味着更高的安全性能，达世币能为更多来自全球各地的终端用户提供全天候的数字货币服务。",
  },
  LINKUSDT: {
    name: "LINK",
    code: "LINK/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "--",
    publicTotal: "1,000,000,000",
    publicCurrent: "350,000,000",
    publicPrice: "--",
    shu: "https://link.smartcontract.com/",
    publicWeb: "--",
    publicSearch: "--",
    dec: "ChainLink的LINK网络是第一个分散的oracle网络，允许任何人安全地提供智能合同，访问关键的外部数据，脱机支付和任何其他API功能。任何拥有数据Feed，脱机服务（如本地付款）或任何其他API的用户均可以直接将其提供给智能合同，以换取LINK令牌。",
  },
  DOTUSDT: {
    name: "DOT",
    code: "DOT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2019/05/05",
    publicTotal: "1,000,000,000",
    publicCurrent: "904,858,113",
    publicPrice: "--",
    shu: "https://polkadot.network/PolkaD",
    publicWeb: "https://polkadot.network/",
    publicSearch: "https://polkadot.js.org/apps/#/e",
    dec: "Polkadot是一个由多条区块链，异构组成的区块链集合。Polkadot主要的目的是将现在各自独立的区块链连接起来。通过Polkadot，不同区块链之间可以进行通信和数据的传递。 Polkadot这个项目成立于2015年。Polkadot项目的主要负责人是大名鼎鼎的Dr. Gavin Wood。Dr. Gavin Wood是以太坊的创始人之一，也是以太坊黄皮书的制作者。Dr. Gavin Wood离开以太坊之后成立了自己的科技公司Parity。目前Polkadot这个项目由Parity公司管理。 DOT是Polkadot平台的原生代币，主要用于：管理平台；运营网络；通过粘合DOT创建区块链；支付信息传送费用。\n",
  },
  BSVUSDT: {
    name: "BSV",
    code: "BSV/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018/11/09",
    publicTotal: "21,000,000",
    publicCurrent: "--",
    publicPrice: "--",
    shu: "--",
    publicWeb: " https://bitcoinsv.io/",
    publicSearch: "--",
    dec: "\t比特币 SV 是比特币现金（BCH）的全节点实现。应比特币现金（BCH）矿业巨头 CoinGeek 及其他矿工的要求，团队创建了比特币 SV，旨在为矿工提供明确的比特币现金（BCH）实现选择，并允许企业在其稳固可靠的基础上构建应用程序和网站。",
  },
  ATOMUSDT: {
    name: "ATOM",
    code: "ATOM/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2019-03-15",
    publicTotal: "237,928,231",
    publicCurrent: "--",
    publicPrice: "$0.1",
    shu: "https://cosmos.network/resources/whitepaper",
    publicWeb: " https://cosmos.network/",
    publicSearch: "--",
    dec: "Cosmos是Tendermint团队推出的一个支持跨链交互的异构网络，由许多独立的并行区块链组成，采用Tendermint共识算法，允许大量自主且易开发的区块链互相扩展和交互，解决区块链价值孤岛的问题，建立价值网络的桥梁。ATOM是Cosmos Hub的代币，可用于垃圾邮件防范机制、抵押分红以及社区管理投票机制。",
  },
  FILUSDT: {
    name: "FIL",
    code: "FIL/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "--",
    publicTotal: "2,000,000,000",
    publicCurrent: "--",
    publicPrice: "--",
    shu: "https://filecoin.io/filecoin.pdf",
    publicWeb: "https://filecoin.io",
    publicSearch: "https://stats.testnet.filecoin.io/d/z6FtI92Zz/chain?orgId=1&refresh=45s&from=now-30m&to=now&kiosk",
    dec: "Filecoin是一个存储文件的对等网络，具有内置的经济激励机制，可确保随着时间的推移可靠地存储文件。 在Filecoin中，用户付费将其文件存储在存储矿工上。存储矿工是负责存储文件并证明其已随时间正确存储文件的计算机。任何想要存储其文件或希望因存储其他用户的文件而获得报酬的人都可以加入Filecoin。可用存储空间以及该存储空间的价格不受任何一家公司的控制。相反，Filecoin促进了开放市场来存储和检索任何人都可以参与的文件。 Filecoin包括区块链和本机加密货币（FIL）。存储矿工通过存储文件赚取FIL单位。",
  },
  AAVEUSDT: {
    name: "AAVE",
    code: "AAVE/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-10-03",
    publicTotal: "16,000,000",
    publicCurrent: "10,642,338",
    publicPrice: "$1.7",
    shu: "https://github.com/aave/aave-protocol/blob/master/docs/A",
    publicWeb: " https://aave.com/",
    publicSearch: "https://www.oklink.com/eth/token/0x7fc66500c84a76ad7",
    dec: "2018年9月，ETHLend更名为Aave，ETHLend是一个分散的p2p借贷平台，允许世界各地的人们获得贷款或成为贷款人。作为一个去中心化的平台，使用区块链技术，我们可以让你在几分钟内以安全的方式获得贷款或融资。自第一次发布以来，加入了许多功能，如比特币作为抵押品，众筹，部分抵押品呼叫，新的货币，例如稳定的硬币，超过150+ ERC20抵押品和更多。“ Aave”是一个芬兰语的单词，它的意思是“幽灵”。“幽灵”代表Aave致力于为分散式金融创建透明、开放的基础架构而努力。Aave的总部位于英国伦敦，由18名创新创造性的业界佼佼者们组成。",
  },
  ADAUSDT: {
    name: "ADA",
    code: "ADA/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2010-02-17",
    publicTotal: "450亿",
    publicCurrent: "57.62%",
    publicPrice: "$0.0163",
    shu: " https://cardanofoundation.org",
    publicWeb: " https://cardanofoundation.org",
    publicSearch: "https://cardanoexplorer.com",
    dec: "卡尔达诺（艾达币）是一个分散且公开的区块链，也是ㄧ个加密货币项目，而且是完全开源的。卡尔达诺正在开发一个智能合约平台，旨在提供比以前开发的任何协议更先进的功能。它是第一个从科学哲学和以研究为首驱使导向，进而演变而来的区块链平台。开发团队由全球专业工程师和研究人员组成。",
  },
  UNIUSDT: {
    name: "UNI",
    code: "UNI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-09-17",
    publicTotal: "1000000000",
    publicCurrent: "24710190",
    publicPrice: "--",
    shu: "https://uniswap.org/whitepaper.pdf",
    publicWeb: "https://uniswap.org/",
    publicSearch: "https://www.oklink.com/eth/token/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    dec: "Uniswap是一种基于以太坊的协议，旨在促进ETH和ERC20 代币数字资产之间的自动兑换交易，在以太坊上自动提供流动性。Uniswap完全部署在链上，任何个人用户，只要安装了去中心化钱包软件都可以使用这个协议。Uniswap试图利用去中心化协议来让数字资产交易过程中彻底实现去中介化。",
  },
  HTUSDT: {
    name: "HT",
    code: "HT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-01-24",
    publicTotal: "24999.2万",
    publicCurrent: "19680.9万",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://www.huobitoken.com/zh-cn/",
    publicSearch: "https://etherscan.io/address/0x6f259637dcd74c767781e37bc6133cd6a68aa161",
    dec: "HT（火币全球通用积分）是基于区块链发行和管理的积分系统，为火币官方发行的唯一积分。HT将支持火币全球业务、全新产品，如：VIP手续费折扣、认证商家保证金、火币积分专享活动、与热门币种交易、参与火币业务等。",
  },
  XMRUSDT: {
    name: "XMR",
    code: "XMR/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2014-04-18",
    publicTotal: "17,497,471",
    publicCurrent: "17,497,471",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://getmonero.org",
    publicSearch: "https://getmonero.org",
    dec:
      "门罗币（Monero）使用加密来屏蔽发送和接收地址以及交易金额，与其他公共区块链像比特币和以太坊不同。\n" +
      "默认情况下，每个门罗币交易都会混淆发送和接收地址以及交易金额。门罗币是可以替代的。这意味着门罗币将永远被接收而没有审查风险。\n" +
      "门罗不是一家公司。它由来自世界各地的加密学和分布式系统专家开发，他们捐赠时间或由社区捐赠资助。这意味着门罗不能被任何一个国家关闭，也不受任何特定法律管辖区的限制。",
  },
  ZECUSDT: {
    name: "ZEC",
    code: "ZEC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2016-10-28",
    publicTotal: "21,000,000",
    publicCurrent: "9,552,481",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://z.cash/",
    publicSearch: "https://explorer.zcha.in/",
    dec: "Zcash是一种去中心化、开源的加密互联网货币，基于14年IEEE会议上发布的paper Zerocash Protocol开发，采取零知识证明及多重账户机制保障交易的匿名性",
  },
  XLMUSDT: {
    name: "XLM",
    code: "XLM/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2014-08-01",
    publicTotal: "1000亿",
    publicCurrent: "18,766,663,721",
    publicPrice: "--",
    shu: "https://www.stellar.org/papers/…",
    publicWeb: "https://www.stellar.org",
    publicSearch: "https://stellarchain.io",
    dec: "Stellar网络是一个开放的支付网络，连接不同的金融系统，让任何人都可以为他们的社区建立低成本的金融服务 - 支付，储蓄，贷款和保险。Stellar网络可以像电子邮件一样轻松地让人，公司和金融机构之间直接转移资金。这种互联性意味着更多的个人访问，更低的银行成本和更多的企业收入。",
  },
  ZRXUSDT: {
    name: "ZRX",
    code: "ZRX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-08-15",
    publicTotal: "10亿",
    publicCurrent: "539,638,687",
    publicPrice: "--",
    shu: "https://www.0xproject.com/pdfs/0x_white_paper.pdf",
    publicWeb: "https://0xproject.com/",
    publicSearch: "--",
    dec: "0x是一个以太坊上的去中心化交易的开源协议,以促进以太坊的资产,例如 ERC20 代币,的去信任和低摩擦交易。该协议旨在作为开放标准和通用模块,推动具有交易功能去中心化应用（DApps）之间的互通性。",
  },
  BATUSDT: {
    name: "BAT",
    code: "BAT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "--",
    publicTotal: "15亿",
    publicCurrent: "1,338,750,672",
    publicPrice: "--",
    shu: "https://basicattentiontoken.org/BasicAttentionTokenWhitePaper-4.pdf",
    publicWeb: "https://basicattentiontoken.org/",
    publicSearch: "--",
    dec: "BAT(Basic Attention Token)是Javascript创始人，Mozilla和Firefox浏览器联合创始人Brendan Eich创办的一个用于在广告商和用户之间进行流通的数字资产，用于解决浏览器中的广告展示和用户激励问题。",
  },
  KNCUSDT: {
    name: "KNC",
    code: "KNC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-09-15",
    publicTotal: "215,625,198",
    publicCurrent: "134,132,696.93",
    publicPrice: "1/600 ETH",
    shu: "https://home.kyber.network/assets/KyberNetworkWhitep",
    publicWeb: "https://home.kyber.network",
    publicSearch: "--",
    dec: "Kyber Network是一个提供即时转换和具有流动性保证的，去中心化和无需信任的加密资产交易协议。我们的协议将是促进区块链生态系统中加密资产流动性的关键，将推进大众对于加密代币作为可交易资产的认知。",
  },
  YFIUSDT: {
    name: "YFI",
    code: "YFI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-07-18",
    publicTotal: "30,000",
    publicCurrent: "29,962",
    publicPrice: "$34",
    shu: "https://github.com/iearn-finance",
    publicWeb: "https://yearn.finance/",
    publicSearch: "https://www.oklink.com/eth/address/0x0bc529c00c6401",
    dec: "Yearn.finance是一个去中心化金融(DeFi)平台，其旨在建立聚合流动性池，杠杆交易平台，自动做市，及其他功能型平台。 YFI是yearn.finance平台中的原生功能型代币。用户可以通过给平台的聚合流动性池，既ypool, 提供流动性来获得此代币。YFI代币可被用于平台治理。 yearn.finance 当前为借币方提供服务，并自动将其期望借出的代币与dYdX, Aave, and Compound之间自动分配划转以获得最高收益。 该平台也会陆续发布其他产品，包括yVault, 为Synthetix, mStable等其他项目提供更多套利策略。",
  },
  MKRUSDT: {
    name: "MKR",
    code: "MKR/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-01-30",
    publicTotal: "1,000,000",
    publicCurrent: "610,000",
    publicPrice: "--",
    shu: "https://www.okex.com/spot/trade/makerdao.com/whitepaper",
    publicWeb: "https://makerdao.com/",
    publicSearch: "https://www.oklink.com/eth/address/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
    dec: "MakerDAO 是以太坊上的去中心化自治组织和智能合约系统，提供以太坊上第一个去中心稳定货币 Dai。 DAI 是有数字资产抵押背书的硬通货，和美元保持1:1锚定。 MKR 是 Maker系统的管理型代币和效用代币，用来支付借 Dai 的稳定费用以及参与管理系统。与 Dai 稳定货币不同，由于其独特的供给机制和在Maker平台上的作用，MKR的价值和整个系统的表现息息相关。",
  },
  XTZUSDT: {
    name: "XTZ",
    code: "XTZ/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-01-30",
    publicTotal: "763,306,930",
    publicCurrent: "607,489,041",
    publicPrice: "--",
    shu: "https://tezos.com/static/position_paper-841a0a56b573afb28da16f6650152fb4.pdf",
    publicWeb: "tezos.com",
    publicSearch: "--",
    dec: "Tezos是一个具有图灵完备智能合约的区块链网络。主网络已经与2018年9月正式上线。Tezos早于2014年就已经注意到了区块链网络的治理缺陷，最早提出了链上自治的解决方案。Tezos的协议被经过精心设计，以便于更加方便地进行参数修正与协议迭代更新。通过链上自治系统，代币持有者可以决定系统的升级路线与优先级，这将有助于解决争端，告别网络硬分叉。此外，Tezos采用特有的LPOS机制，目前已经有超过470多位来自世界各地的验证者参与保护网络，再加之代码层面的形式验证设计，最大限度保证了整个网络的安全性。",
  },
  OMGUSDT: {
    name: "OMG",
    code: "OMG/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-06-27",
    publicTotal: "14024.54万",
    publicCurrent: "100%",
    publicPrice: "$0.27",
    shu: "https://cdn.omise.co/omg/whit…",
    publicWeb: "https://etherscan.io/token/Omis…",
    publicSearch: "--",
    dec: "基于以太坊实现实时的点对点价值交换和支付服务。",
  },
  CRVUSDT: {
    name: "CRV",
    code: "CRV/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-08-14",
    publicTotal: "1,374,214,451",
    publicCurrent: "130,669,518",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://curve.finance/",
    publicSearch: "https://www.oklink.com/eth/address/0xD533a949740bb3",
    dec: "Curve是一个以太坊上的去中心化流动池交易所，提供极其高效的稳定币交易。Curve于2020年1月上线，它能够让用户以低滑点、低手续费交易稳定币。用户可以在提供流动性的时候捕获其平台的交易费用，同时其接入了DeFi协议iearn和Compound，为流动性提供者提供额外收益，自动化兑换的方式也对用户使用更加友好。",
  },
  SNXUSDT: {
    name: "SNX",
    code: "SNX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-03-12",
    publicTotal: "212,424,133",
    publicCurrent: "110,519,345",
    publicPrice: "$0.9",
    shu: "https://docs.synthetix.io/litepaper/",
    publicWeb: "https://www.synthetix.io/",
    publicSearch: "https://www.oklink.com/eth/address/0xc011a73ee8576fb",
    dec: "Synthetix是2018年基于以太坊搭建的衍生品流动性协议，旨在实现合成资产的发行和交易。Synthetix目前支持发行的合成资产包括法币、加密货币、大宗商品。原则上，该系统可以对无限的现实资产提供链上敞口。 Synths由Synthetix Network Token（SNX）抵押产生，参与staking可以收取由Synth交易量产生的费用。",
  },
  DOGEUSDT: {
    name: "DOGE",
    code: "DOGE/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-03-12",
    publicTotal: "无限，每个区块10000DOGE",
    publicCurrent: "118,933,137,118",
    publicPrice: "$0.9",
    shu: "--",
    publicWeb: "https://dogecoin.com",
    publicSearch: "https://dogechain.info/chain/Dogecoin",
    dec: "Dogecoin是一个致力于像货币一样有真正实用价值的加密货币。我们有更快的区块间隔和极低的费率，使得Dogecoin适用于小额支付和在线购物。Dogecoin已经被在线商家所采用，并且可以很容易地被用作消费者之间的转账手段。",
  },
  SHIBUSDT: {
    name: "SHIB",
    code: "SHIB/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "10,000 CNY",
    lever: "10X~50X",
    publicTime: "--",
    publicTotal: "1000亿",
    publicCurrent: "--",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://shibatoken.com/",
    publicSearch: "https://etherscan.io/token/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce#balances",
    dec: "SHIB是在区中心化的自发社区建设中进行的一项实验。SHIB令牌是团队的第一个令牌。如果您对SHIB不了解，那么可以对标一下狗狗币。SHIB的发行量为1000亿枚，团队把代币总供应量的50%锁定在Uniswap上，并自称已经焚毁账户的钥匙。剩下的50%转移到了Vitalik Buterin的钱包地址。项目的开发者以及团队人员手中没有预留的代币，因此每个人都必须在公开市场上购买。",
  },

  RENUSDT: {
    name: "REN",
    code: "REN/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-02-22",
    publicTotal: "999,999,633",
    publicCurrent: "881,153,018",
    publicPrice: "$0.56",
    shu: "https://republicprotocol.github.io/whitepaper/republic-whitepaper.pdf",
    publicWeb: "https://republicprotocol.com/",
    publicSearch: "https://www.oklink.com/eth/address/0x408e41876cCCDC0F92210600ef50372656052a38",
    dec: "Republic Protocol（REN）是一个BTC/ETH，BTC/ERC20和ETH/ERC20之间大量加密资产在分布式暗池交易的协议，是目前所知唯一拥有此类分布式加密资产暗池交易技术的区块链企业。它使用原子交换交易比特币，以太币以及其他代币。交易被收录在隐式订单簿上，通过多方计算协议引擎进行匹配，这既保证了订单在执行前都不会暴露其具体细节,同时也为其他交易者带来便利。 Republic Protocol系统依靠精心设计的激励系统来保证玩家遵守规则。RenEx“Dark Nodes”运行撮合引擎来保证暗池的运行，同时多个暗池的存在将减轻交易对手的风险。为了防止恶意攻击，规定网络节点（Darknodes）必须支付100000个Ren，这个也就是Republic Protocol的Token。",
  },
  SUSHIUSDT: {
    name: "SUSHI",
    code: "SUSHI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-08-27",
    publicTotal: "170,875,267",
    publicCurrent: "127,244,443",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://sushiswap.org/",
    publicSearch: "https://www.oklink.com/eth/address/0x6b3595068778dd5",
    dec: "SushiSwap是一个Uniswap的分叉，它采用了UniSwap的核心流动池设计，但是加入了代币挖矿的超强社区属性。可以说Sushiswap = UniSwap + YAM。",
  },

  EURUSD: {
    name: "欧元美元",
    code: "EURUSD",
    type: "外汇",
    currency: "美元(USD)",
    buyTimeAM: "06:00-05:50",
    sellTimeAM: "06:00-05:55",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "200X~500X",
  },
  GBPUSD: {
    name: "英镑美元",
    code: "GBPUSD",
    type: "外汇",
    currency: "美元(USD)",
    buyTimeAM: "06:00-05:50",
    sellTimeAM: "06:00-05:55",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "200X~500X",
  },
  AUDUSD: {
    name: "澳元美元",
    code: "AUDUSD",
    type: "外汇",
    currency: "美元(USD)",
    buyTimeAM: "06:00-05:50",
    sellTimeAM: "06:00-05:55",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "200X~500X",
  },
  USDCAD: {
    name: "美元加元",
    code: "USDCAD",
    type: "外汇",
    currency: "美元(USD)",
    buyTimeAM: "06:00-05:50",
    sellTimeAM: "06:00-05:55",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "200X~500X",
  },
  USDCHF: {
    name: "美元瑞郎",
    code: "USDCHF",
    type: "外汇",
    currency: "美元(USD)",
    buyTimeAM: "06:00-05:50",
    sellTimeAM: "06:00-05:55",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "200X~500X",
  },
  NZDUSD: {
    name: "纽元美元",
    code: "NZDUSD",
    type: "外汇",
    currency: "美元(USD)",
    buyTimeAM: "06:00-05:50",
    sellTimeAM: "06:00-05:55",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "200X~500X",
  },
  USDJPY: {
    name: "美元日元",
    code: "USDJPY",
    type: "外汇",
    currency: "美元(USD)",
    buyTimeAM: "06:00-05:50",
    sellTimeAM: "06:00-05:55",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "200X~500X",
  },

  700: {
    name: "腾讯",
    code: "00700",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.tencent.com",
    dec: "腾讯控股有限公司是一家主要提供增值服务及网络广告服务的投资控股公司。该公司通过三大分部运营。增值服务分部主要包括互联网及移动平台提供的网络/手机游戏，社区增值服务及应用。网络广告分部主要包括效果广告及展示广告。其他分部主要包括支付相关服务，云服务及其他服务。",
  },
  9988: {
    name: "阿里巴巴",
    code: "09988",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.alibabagroup.com",
    dec: "阿里巴巴集团控股有限公司1999年6月28日成立于开曼群岛。公司的使命是让天下没有难做的生意。为了支持小企业发展，公司创始人创办了公司。公司相信互联网能够创造公平的环境，让小企业通过创新与科技拓展业务，并更有效地参与国内及国际市场竞争。公司的决策以长期使命为指引，而非着眼于短期利益。公司旨在构建未来的商业基础设施。公司的愿景是让客户相会，工作和生活在阿里巴巴。随着公司的业务不断拓展，从商业拓展至云计算，数字媒体及娱乐等众多其他领域，阿里巴巴已进化为一个独特的，充满活力与创新的数字经济体。公司已设立未来5年的战略总目标服务全球消费者，其中有超过10亿的中国消费者，创造10万亿人民币以上的消费规模。公司相信五年目标使公司能更接近实现2036年的愿景服务全世界20亿消费者，帮助1000万家中小企业盈利以及创造一亿就业机会。",
  },
  9618: {
    name: "京东集团",
    code: "09618",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://ir.jd.com",
    dec: "JD.com Inc 是一家主要从事电商业务的控股公司。该公司运营两个业务部门。京东零售部门包括中国的自营业务，平台业务及广告服务。该公司提供数码产品，家用电器和消费品类产品。该公司有其线上平台，第三方商家可向其平台提供产品。该公司在多个网站渠道向第三方商家，供应商及其他业务合作伙伴提供营销以及展示广告服务。新业务部门包括向第三方提供的物流服务，海外业务，技术创新以及向物流物业投资者提供的资产管理服务以及出售开发物业。该公司通过京东物流向第三方提供全面的供应链解决方案，包括仓储，运输，配送及售后服务。该公司主要在国内市场开展业务。",
  },
  1810: {
    name: "小米集团",
    code: "01810",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.mi.com",
    dec: '小米是一家以手机，智能硬件和IOT平台为核心的互联网公司。以独特且强大的商业模式由三个相互协作的支柱组成。创新，高质量，精心设计且专注于卓越用户体验的硬件，使公司能以厚道的价格销售产品的高效新零售和丰富的互联网服务。"',
  },
  3690: {
    name: "美团",
    code: "03690",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.meituan.com",
    dec: "美团（原名：美团点评）是一家中国生活服务电子商务平台。该公司连接消费者和商家，提供满足人们日常“吃”的需求和各种服务。该公司拥有即时配送服务品牌“美团外卖”，并通过其移动端提供服务。该公司还拥有共享单车服务品牌“摩拜单车”。",
  },
  1024: {
    name: "美团",
    code: "01024",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.kuaishou.com",
    dec: "快手科技是一家主要运营内容社区和社交平台的中国投资控股公司。该公司主要提供直播服务，线上营销服务及其他服务。其中线上营销解决方案包括广告服务，快手粉条及其他营销服务。其他服务包括电商，网络游戏及其他增值服务。该公司主要于国内市场开展业务。",
  },
  6862: {
    name: "海底捞",
    code: "06862",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.haidilao.com",
    dec: "海底捞国际控股有限公司成立于2015年7月，海底捞是全球领先，快速增长的中式餐饮品牌，主打火锅品类。公司认为海底捞在中式餐饮行业已形成一种独特的文化现象，成为极致服务体验和就餐体验的代名词。餐饮服务行业是劳动密集型行业，如何解决规模化，标准化，控制食品安全是长期存在的痛点。公司解决上述行业挑战，实现高质量增长的核心在于“连住利益，锁住管理”的独特模式。公司相信这这种模式可超越文化和地区的边界，满足人们对美好生活的普遍追求，并成功运用于中国及海外市场。根据沙利文报告，按2017年收入计算，公司在中国和全球的中式餐饮市场中均排名第一。亦为中国及全球增长最快的主要中式餐饮品牌。截止2018年9月3日，公司拥有及运营的餐厅数量达363家，包括内地332家以及31家位于台湾，香港及海外在新加坡，韩国，日本及美国的餐厅。",
  },
  981: {
    name: "中芯国际",
    code: "00981",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.smics.com",
    dec: "中芯国际集成电路制造有限公司是一家主要从事集成电路（IC）的电脑辅助设计，制造，测试，封装及买卖业务并提供半导体服务的投资控股公司。该公司还设计和制造半导体掩膜。该公司主要产品为多种种类晶圆。该公司产品销往中国国内于海外市场，如美国和欧洲。",
  },
  1833: {
    name: "平安好医生",
    code: "01833",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.jk.cn",
    dec: "平安好医生成立于2014年8月，是平安集团旗下互联网业务板块的重要成员，以医生资源为核心，提供实时咨询和健康管理服务，包括一对一家庭医生服务，三甲名医的专业咨询和额外门诊加号等。",
  },
  6185: {
    name: "康希诺生物",
    code: "06185",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.cansinotech.com",
    dec: "康希诺生物股份公司成立于2009年1月。使命是研发生产和商业化高质量创新及经济实惠的疫苗。公司团队云集来自赛诺菲巴斯德阿斯利康和惠氏等全球制药公司的世界顶尖科学家，他们带领过一些国际重磅疫苗的研发。中国的疫苗市场庞大且供不应求。根据灼识咨询报告，公司打算重点发展私人疫苗市场，预期该市场将于2022年之前主导中国整体疫苗市场。随着人们对疫苗接种的益处认识日益提高，更多人有能力于私人市场购买疫苗，政府对预防性医疗保健的支出不断增加及政策支持力度不断加大等因素推动。",
  },
  1211: {
    name: "比亚迪股份",
    code: "01211",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.byd.com/cn/index.html",
    dec: "比亚迪股份有限公司于1995年2月成立，是一家具有民营企业背景的香港上市公司，现拥有IT，汽车以及新能源三大产业，是一家集研究，开发，生产，销售为一体的深圳市重点高新技术企业。随着公司的发展，业务的拓展，公司人数，工业园也急剧增长。到2010年年底，比亚迪已拥有逾20万名员工；从1995年创业时的外租厂房，到2010年年底，比亚迪已建成广东，北京，陕西，上海，长沙等全国各地共十一大工业园，占地面积逾1500万平方米。",
  },
  2382: {
    name: "舜宇光学科技",
    code: "02382",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "https://www.sunnyoptical.com/default.html",
    dec: "舜宇光学科技（集团）有限公司是中国领先的光学产品制造企业，具备全面的设计实力及专业生产技术，公司在光学非球面技术，AF/ZOOM和多层镀膜等多项核心技术的研究和应用上处于国内领先水平。公司主要从事光学相关产品的开发，制造和销售，目前产品包括光学零件，光电产品和光学仪器。",
  },
  992: {
    name: "联想集团",
    code: "00992",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.lenovo.com.cn",
    dec: "联想专注于制造卓越的个人电脑。联想的商业模式构筑于创新，运营效率，客户满意度以及对新兴市场的投入。集团由联想及原IBM个人电脑事业部组成，从事开发，制造及在世界各地销售可靠，高质，安全及操作简易的产品及服务。联想在日本大和，中国北京，上海，深圳，美国北卡罗莱纳州的罗利均设有主要研发中心。",
  },
  2269: {
    name: "药明生物",
    code: "02269",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.wuxibiologics.com",
    dec: "药明生物技术有限公司是一家主要提供生物制剂发现，开发及生产服务的投资控股公司。该公司的技术平台包括WuXiBodyTM双特异抗体技术平台及抗体偶联药物(ADC)技术平台等。其引入多个新项目至研发管线。该公司有疫苗生产基地，为客户生产疫苗以供应全球市场。该公司于中国市场及北美，欧洲和其他海外市场开展项目。",
  },
  9888: {
    name: "百度集团",
    code: "09888",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://ir.baidu.com",
    dec: "百度公司是一家中文互联网搜索服务的提供商。该公司网站Baidu.com提供一个中文搜索平台，用户可通过其网站提供的链接，在线查找包括网页，新闻，图片，文档和多媒体文件等多种信息。该公司通过百度核心业务和爱奇艺两个分部运营。百度核心业务主要提供基于搜索，信息流及其他在线营销服务，以及来自该公司AI新领域的产品和服务。产品和服务分为移动生态，百度智能云及智能驾驶与其他增长计划。爱奇艺为在线娱乐服务供应商，在其平台提供用户原创，专业制作以及合作方制作的内容。",
  },
  1357: {
    name: "美图公司",
    code: "01357",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://corp.meitu.com",
    dec: "美图公司于2013年7月成立，公司提供创新的影像和社区应用矩阵，受到海内外关注，并在中国掀起自拍风潮。为更好的满足用户对更优质自拍照的需求，公司于2013年推出专为自拍设计的美图手机，这也是公司实现变现的首个重大举措。智能硬件的收益分别占公司截至2013年，2014年及2015年12月31日止年度以及截至2016年6月30日止6个月总收益的59.7%，87.8%，89.9%及95.1%",
  },
  3888: {
    name: "金山软件",
    code: "03888",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.kingsoft.com",
    dec: "金山软件是中国最知名的软件企业之一，中国领先的应用软件和互联网服务提供商。目前金山软件在珠海，北京，成都，大连，深圳五地分设研发中心，创造了WPS Office，金山词霸，金山毒霸，剑侠情缘，封神榜等众多知名产品。同时，金山旗下拥有国内知名的大型英语学习社区爱词霸以及在线游戏交流社区逍遥网。2007年10月9日，金山软件在香港主板成功上市。",
  },
  708: {
    name: "恒大汽车",
    code: "00708",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.evergrandehealth.com",
    dec: "中国恒大新能源汽车集团有限公司是一家主要从事健康管理的公司。该公司经营两个分部。健康管理分布的业务包括互联网+社区健康管理，国际医院，养老及康复产业，医学美容和抗衰老，以及销售养生空间。新能源汽车分部从事研发，生产和销售新能源汽车，包括销售锂电池和汽车组件，以及提供技术服务。",
  },
  2338: {
    name: "潍柴动力",
    code: "02338",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.weichaipower.com",
    dec: "潍柴动力股份有限公司是由原潍坊柴油机厂联合境内外投资者设立的符合现代企业制度的企业，是中国第一家在香港H股上市，并回归内地实现A股再上市的企业，是中国最大的汽车零部件企业集团。公司三大业务板块（动力总成，商用车，汽车零部件）在国内各自细分市场均处于绝对优势地位。",
  },
  9633: {
    name: "农夫山泉",
    code: "09633",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.nongfuspring.com",
    dec: "农夫山泉股份有限公司是一家主要从事生产和销售包装饮用水的公司。该公司经营五个分部。水类产品分部从事生产和销售包装饮用水，其品牌为农夫山泉。功能饮料分部从事生产和销售功能饮料，其品牌包括尖叫和力量帝维他命水。即饮茶类产品分部从事生产和销售即饮茶，其品牌包括东方树叶。果汁饮料产品分部从事生产和销售果汁饮料，其品牌包括农夫果园，水溶C100和NFC。其他产品分部从事生产和销售农产品和其他饮料产品，比如苹果，大米和植物酸奶产品。",
  },
  2331: {
    name: "李宁",
    code: "02331",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.lining.com",
    dec: "李宁有限公司为中国领先的体育品牌企业之一，拥有品牌营销，研发，设计，制造，经销及零售能力，产品主要包括自有李宁品牌之运动及休闲鞋类，服装，配件和器材产品。本集团主要采用外包生产和特许分销商模式，已于中国建立庞大的供应链管理体系以及分销和零售网络。本集团亦自行经营李宁牌零售店。",
  },
  241: {
    name: "阿里健康",
    code: "00241",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.alijk.com",
    dec: "阿里健康信息技术有限公司为一间综合信息及内容服务供应商。集团主要业务包括电讯及信息增值服务，提供产品质量电子监管网，系统集成及软件开发。于年内，集团已显著提升电子监管网的整体效率。未来集团将进一步加强电子监管网的基础设施，提高系统的实用性，易用性和管理性。",
  },
  941: {
    name: "中国移动",
    code: "00941",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://www.chinamobileltd.com",
    dec: "中国移动有限公司于1997.10.22和23日分别在纽约交易所和香港交易所上市。公司股票在1998.1.27成为香港恒生指数成份股。集团是中国内地最大的移动通信服务供应商，拥有全球最多的移动用户和全球最大规模的移动通信网络。",
  },
  9626: {
    name: "哔哩哔哩",
    code: "09626",
    exchange: "",
    type: "港股",
    currency: "港元(HK)",
    buyTimeAM: "09:30:00-12:00:00 13:00:00-15:50:00",
    sellTimeAM: "09:30:00-12:00:00 13:00:00-15:55:00",
    clearTime: "15:55:00",
    charge: "保证金*杠杆*0.36%",
    overnight: "保证金*杠杆*0.18%",
    position_time: "15:55:00自动平仓",
    overnight_fee: "每日15:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~20X",
    publicWeb: "http://ir.bilibili.com",
    dec: "Bilibili inc是一家主要从事互联网相关业务的中国公司。公司包括四个业务部门。移动游戏业务部门主要为第三方游戏开发商发行可以免费下载并以哔哩哔哩账号游玩的移动游戏。广告业务部门主要从事广告展示业务。直播和增值服务业务部门主要通过大会员的订阅费，直播频道内虚拟礼物的销售付费内容和虚拟物品获取收入。电商及其他部门主要通过线上销售ACG相关商品和线下表演及活动票务的收入。",
  },
  ZF: {
    name: "美债5年期",
    code: "ZF",
    exchange: "",
    type: "利率期货",
    currency: "美元(USD)",
    buyTimeAM: "夏令时：上午06:00:00-次日04:50:00 </br> 冬令时：上午07:00:00-次日05:50:00",
    sellTimeAM: "夏令时：上午06:00:00-次日04:55:00 </br> 冬令时：上午07:00:00-次日05:55:00",
    position_time: "夏令时：04:55:00自动平仓 </br> 冬令时：05:55:00自动平仓",
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.015%",
    overnight_fee: "夏令时：每日04:55:00 </br> 冬令时：每日05:55:00 </br>",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "30X~80X",
  },
  UZN: {
    name: "美债10年期",
    code: "ZN",
    exchange: "",
    type: "利率期货",
    currency: "美元(USD)",
    buyTimeAM: "夏令时：上午06:00:00-次日04:50:00 </br> 冬令时：上午07:00:00-次日05:50:00",
    sellTimeAM: "夏令时：上午06:00:00-次日04:55:00 </br> 冬令时：上午07:00:00-次日05:55:00",
    position_time: "夏令时：04:55:00自动平仓 </br> 冬令时：05:55:00自动平仓",
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.015%",
    overnight_fee: "夏令时：每日04:55:00 </br> 冬令时：每日05:55:00 </br>",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "30X~80X",
  },
  ZB: {
    name: "美债30年期",
    code: "ZB",
    exchange: "",
    type: "利率期货",
    currency: "美元(USD)",
    buyTimeAM: "夏令时：上午06:00:00-次日04:50:00 </br> 冬令时：上午07:00:00-次日05:50:00",
    sellTimeAM: "夏令时：上午06:00:00-次日04:55:00 </br> 冬令时：上午07:00:00-次日05:55:00",
    position_time: "夏令时：04:55:00自动平仓 </br> 冬令时：05:55:00自动平仓",
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.015%",
    overnight_fee: "夏令时：每日04:55:00 </br> 冬令时：每日05:55:00 </br>",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "30X~80X",
  },
  GBM: {
    name: "欧债5年期",
    code: "GBM",
    exchange: "",
    type: "利率期货",
    currency: "欧元(EUR)",
    buyTimeAM: "上午08:15:00-次日03:50:00",
    sellTimeAM: "上午08:15:00-次日03:55:00",
    position_time: "03:55:00自动平仓",
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.015%",
    overnight_fee: "每日03:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "30X~80X",
  },
  GBL: {
    name: "欧债10年期",
    code: "GBL",
    exchange: "",
    type: "利率期货",
    currency: "欧元(EUR)",
    buyTimeAM: "上午08:15:00-次日03:50:00",
    sellTimeAM: "上午08:15:00-次日03:55:00",
    position_time: "03:55:00自动平仓",
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.015%",
    overnight_fee: "每日03:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "30X~80X",
  },
  GBX: {
    name: "欧债30年期",
    code: "GBX",
    exchange: "",
    type: "利率期货",
    currency: "欧元(EUR)",
    buyTimeAM: "上午08:15:00-次日03:50:00",
    sellTimeAM: "上午08:15:00-次日03:55:00",
    position_time: "03:55:00自动平仓",
    charge: "保证金*杠杆*0.03%",
    overnight: "保证金*杠杆*0.015%",
    overnight_fee: "每日03:55:00",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "30X~80X",
  },
  // 20220223 新增數幣規則
  AVAXUSDT: {
    name: "AVAX",
    code: "AVAX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/9/22",
    publicTotal: "720,000,000",
    publicCurrent: "244,862,355",
    publicPrice: "--",
    shu: "https://files.avalabs.org/papers/token.pdf",
    publicWeb: "https://www.avalabs.org/",
    publicSearch: "https://explorer.avax.network/",
    dec: "\tAvalanche是一个开源平台，可以基于这个平台启动去中心化的应用、新的金融原生功能，以及新的可互操作的区块链项目。Avalanche使用突破性的共识协议构建而成，是第一个可在一秒钟内确认交易的智能合约平台，支持以太坊开发工具包的全部功能，并支持大量的完整区块生产者。",
  },
  LUNAUSDT: {
    name: "LUNAUSDT",
    code: "LUNA/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2019/07/25",
    publicTotal: "800,361,400",
    publicCurrent: "356,491,192",
    publicPrice: "$0.8",
    shu: "https://terra.money/Terra_White_paper.pdf",
    publicWeb: "https://www.terra.money/",
    publicSearch: "https://finder.terra.money/",
    dec: "\tTerra正在设计的价格稳定型数字货币，将为下一代区块链支付网络提供驱动力。Terra建立了一个用户保持持续增长的遍布全球的电子商务联盟，将区块链交易的低成本优势，带给商家及消费者。Terra的目标是将数字货币和实际应用联系在一起，成长为一个创新型的金融去中心化应用程序（dApp）的开放平台，实现区块链经济的实际增长。",
  },
  SOLUSDT: {
    name: "SOL",
    code: "SOL/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/03/22",
    publicTotal: "488,630,611",
    publicCurrent: "314,690,853",
    publicPrice: "$0.22",
    shu: "https://solana.io/solana-whitepaper.pdf",
    publicWeb: "https://solana.com/zh",
    publicSearch: "https://explorer.solana.com/",
    dec: "\tSolana由前高通、英特尔以及Dropbox工程师于2017年末创立，是一个单层区块链，采用委托权益证明协议，其专注点是在不牺牲去中心化或安全性的前提下提供可扩展性。Solana扩展方案的核心是一个名为“历史证明（PoH）”的去中心化时钟，该时钟旨在解决缺乏单个可信赖时间源的分布式网络中的时间问题。通过可验证的延迟功能，PoH算法允许每个节点使用SHA256算法在本地生成时间戳。因此免除了在网络中广播时间戳的需求，从而提高整个网络的效率。",
  },
  AXSUSDT: {
    name: "AXS",
    code: "AXS/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/1103",
    publicTotal: "270,000,000",
    publicCurrent: "71,020,808",
    publicPrice: "--",
    shu: "https://whitepaper.axieinfinity.com/",
    publicWeb: "https://axieinfinity.com/",
    publicSearch: "https://www.oklink.com/zh-cn/eth/token/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
    dec: "\tAxie Infinity是在以太坊区块链上构建的，受神奇宝贝启发的数字宠物世界，任何人都可以通过熟练的游戏玩法和对生态系统的贡献来获得代币奖励。为了创建更好的用户体验并提高可伸缩性，Axie Infinity团队正在构建名为Ronin的第2层侧链。",
  },
  LITUSDT: {
    name: "LIT",
    code: "LIT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2021/2/4",
    publicTotal: "1,000,000,000",
    publicCurrent: "34,834,211",
    publicPrice: "--",
    shu: "https://whitepaper.io/coin/litentry",
    publicWeb: "https://www.lition.io",
    publicSearch: "https://etherscan.io/token/0xb59490ab09a0f526cc7305822ac65f2ab12f9723",
    dec: "\tLitentry是基于多网络的去中心化身份聚合协议，拥有去中心化身份(DID）索引机制和基于Substrate的信用计算网络。",
  },
  UNFIUSDT: {
    name: "UNFI",
    code: "UNFI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/11/21",
    publicTotal: "1000万",
    publicCurrent: "520.6万",
    publicPrice: "--",
    shu: "https://medium.com/unifiprotocol/introducing-the-unifi-protocol-2ad67bc8435",
    publicWeb: "https://unifiprotocol.com/",
    publicSearch: "https://etherscan.io/token/0x441761326490cACF7aF299725B6292597EE822c2",
    dec: "\tUnifi Protocol DAO是一组非托管，可互操作的多链智能合约，旨在为分布式金融产品开发提供工具。uTrade是一个去中心化的自动做市商（AMM）和代币交易平台，是基于Unifi Protocol DAO智能合约构建的第一款产品。UNFI是Unifi Protocol DAO的原生治理代币。用户可以将UNFI抵押并委派给社区理事会代表，并获得UNFI作为奖励。社区理事会代表为该平台做出治理决策并获得奖励。有关平台治理的详细信息见此。用户可以通过两种方式获得UNFI代币: 1. 通过公开市场购买，2. 通过迁移UP代币（UP代币可在为基于Unifi的产品提供流动性的过程中获得）。",
  },
  MATICUSDT: {
    name: "MATIC",
    code: "MATIC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2019/4/25",
    publicTotal: "100亿",
    publicCurrent: 6872890164,
    publicPrice: "$0.0026",
    shu: "https://lith.finance/Lithium_Finance_Litepaper.pdf",
    publicWeb: "https://lith.finance/",
    publicSearch: "https://www.oklink.com/eth/token/0x188e817b02e635d482ae4d81e25dda98a97c4a42",
    dec: "\tMatic运用侧链的链下运算来提供扩展性解决方案，同时运用Plasma和PoS机制来确保资产安性。Matic致力于成为真正可以让开发者部署及运行去中心化应用的平台。Matic是以太坊生态的重要贡献者，对Plasma MVP, WalletConnect 协议和以太坊事件提醒引擎•Dagger做出了突出贡献。",
  },
  SANDUSDT: {
    name: "SAND",
    code: "SAND/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/08/12",
    publicTotal: "3,000,000,000",
    publicCurrent: 921073319,
    publicPrice: "--",
    shu: "https://installers.sandbox.game/The_Sandbox_Whitepaper_2020.pdf",
    publicWeb: "https://www.sandbox.game/sc/",
    publicSearch: "https://www.oklink.com/zh-cn/eth/token/0x3845badade8e6dff049820680d1f14bd3903a5d0",
    dec: "\tThe Sandbox是一个虚拟世界，玩家可以使用平台代币SAND在以太坊区块链中创建、拥有自己的游戏体验并从中获利。在这里你可以玩、创造、收集、赚取、治理和拥有游戏中的任何东西。",
  },
  NEARUSDT: {
    name: "NEAR",
    code: "NEAR/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/10/13",
    publicTotal: "1,000,000,000",
    publicCurrent: 620237377,
    publicPrice: "$0.0375",
    shu: "https://near.org/papers/the-official-near-white-paper/",
    publicWeb: "https://near.org/",
    publicSearch: "https://explorer.near.org/",
    dec: "\tNear Protocol是一个公链平台，是目前DAPP公链中最有竞争力的公链之-，Near拥有比 ETH2.0 更优的分片方案，以及世界顶级的工程能力。NEAR 分片设计与 ETH2.0的不同之处在于，优先考虑跨分片间的网络资源平衡，且不区别对待分片。NEAR 的 Nightshade 共识与“大区块”设计，使其可以充当单条区块链，以达成互联网级别的规模。",
  },
  MANAUSDT: {
    name: "MANA",
    code: "MANA/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2017/09/17",
    publicTotal: "2,193,831,027",
    publicCurrent: 1327372145,
    publicPrice: "$0.02339",
    shu: "https://decentraland.org/whitepaper.pdf",
    publicWeb: "https://decentraland.org/",
    publicSearch: "https://www.oklink.com/zh-cn/eth/token/0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
    dec: "\t基于以太坊区块链的、支持VR的Minecraft 及Second Life式游戏平台。",
  },
  GALAUSDT: {
    name: "GALA",
    code: "GALA/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/9/18",
    publicTotal: "369.2亿",
    publicCurrent: "69.77亿",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://app.gala.games/",
    publicSearch: "https://etherscan.io/token/0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA",
    dec: "\tGala是一个游戏和NFT平台，旨在建立一个致力于区块链游戏的生态系统。GALA是平台的功能型代币，可用于奖励网络节点运行者参与网络治理，通过玩各种平台游戏获得GALA代币，在Gala商店购买\n游戏内物品和NFT。",
  },
  LINAUSDT: {
    name: "LINA",
    code: "LINA/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/09/17",
    publicTotal: "10,000,000,000",
    publicCurrent: "3,267,964,251",
    publicPrice: "--",
    shu: "https://whitepaper.io/coin/linear",
    publicWeb: "https://linear.finance/",
    publicSearch: "https://etherscan.io/token/0x3E9BC21C9b189C09dF3eF1B824798658d5011937",
    dec: "\tLinear Finance是一个跨链兼容，分散式的delta-one资产协议。其巨大价值在于投资者能够轻松、快速地投资，节省费用。",
  },

  APEUSDT: {
    name: "APE",
    code: "APE/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2022/03/17",
    publicTotal: "1,000,000,000 APE",
    publicCurrent: "314,218,750 APE",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://apecoin.com/",
    publicSearch: "https://www.oklink.com/zh-cn/eth/token/0x4d224452801aced8b2f0aebe155379bb5d594381",
    dec: "\tAPE作为APE生态系统的开源协议层，允许第三方开发人员将APE集成到新的和现有的服务、基于区块链的游戏等。APE的推出旨在将APE生态系统的控制权移交给现有的BAYC和MAYC NFT持有者，扩大APE生态系统以包括更广泛的用户群，支持新产品和服务的创建和开发以增强生态系统，并以DAO的形式促进社区治理。",
  },

  DYDXUSDT: {
    name: "DYDX",
    code: "DYDX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2021/09/08",
    publicTotal: "1,000,000,000 DYDX",
    publicCurrent: "126,055,778 DYDX",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://dydx.exchange/",
    publicSearch: "https://www.oklink.com/zh-cn/eth/token/0x92d6c1e31e14520e676a687f0a93788b716beff5",
    dec: "\tdYdX 是全球领先的去中心化数字货币衍生品交易平台，它提供永续合约，杠杆交易，现货交易，以及借贷服务。它曾获得Paradigm，A16Z，Polychain以及Coinbase 首席执行官 Brian Armstrong等知名投资者的投资。dYdX已上线二层网络，采用了以太坊二层扩容方案 StarkWare。截止9月7日，它的累计交易量已超过190亿美元。",
  },

  THETAUSDT: {
    name: "THETA",
    code: "THETA/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2018/01/12",
    publicTotal: "--",
    publicCurrent: "1,000,000,000 THETA",
    publicPrice: "--",
    shu: "https://s3.us-east-2.amazonaws.com/assets.thetatoken.org/Theta-white-paper-latest.pdf?v=1544141896.351",
    publicWeb: "https://www.thetanetwork.io/",
    publicSearch: "https://www.oklink.com/zh-cn/eth/token/0x3883f5e181fccaf8410fa61e12b59bad963fb645",
    dec: "\tTheta解决视频流媒体产业面临的挑战。用Theta代币作为奖励机制，可以鼓励用户分享闲置的电脑内存和带宽资源，成为视频流媒体的缓存节点。通过去中心化，Theta代币使流媒体市场效率显著提高。",
  },

  CHZUSDT: {
    name: "CHZ",
    code: "CHZ/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2018/10/22",
    publicTotal: "--",
    publicCurrent: "5,344,064,580 CHZ",
    publicPrice: "--",
    shu: "https://www.chiliz.com/docs/CHZ_whitepaper.pdf",
    publicWeb: "https://www.chiliz.com/en/",
    publicSearch: "https://www.oklink.com/eth/token/0x3506424f91fd33084466f402d5d97f05f8e3b4af",
    dec: "\tChilliz（CHZ）是一种用于体育、电子竞技和娱乐平台的数字化货币，适用于众多行业。其目的是促进为体育和娱乐而打造的区块链生态系统。Chilliz可以使体育团队和其他组织创建自己的粉丝代币，并将粉丝代币作为社区内部的兑换媒介。其中一些知名的合伙人包括几个大型足球俱乐部，例如巴塞罗那足球俱乐部、巴黎圣日耳曼足球俱乐部和尤文图斯足球俱乐部，它们均已发行自己的粉丝代币。可在Chilliz Exchange上对这些代币进行交易。此外，应用程序内部体验还允许组织使用Chilliz区块链并通过商品、赠品和独家体验来激活自己的线下粉丝群。",
  },

  ENJUSDT: {
    name: "ENJ",
    code: "ENJ/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2017/11/02",
    publicTotal: "1,000,000,000 ENJ",
    publicCurrent: "1,000,000,000 ENJ",
    publicPrice: "--",
    shu: "https://enjincoin.io/enjincoin_whitepaper.pdf",
    publicWeb: "https://enjin.io/",
    publicSearch: "https://www.oklink.com/eth/token/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
    dec: "\tEnjin提供由软件集成的区块链生态系统，使每个人都可以轻松地开发、交易、通证化各种数字资产，并在区块链市场进行营销活动。Enjin成立于2009年，深耕于游戏行业，公司的第一个产品是一个名为Enjin Network的游戏社区平台，在10年的时间里用户增长到了2000万。Enjin生态系统建立在强大的区块链基础设施之上，使各种规模的游戏开发者和企业能够将数字资产通证化，作为其收购、留存、增强用户粘性和盈利策略的一部分。Enjin生态系统由Enjin代币(ENJ)推动，Enjin代币是一种数字价值存储载体，用于支持NFTs区块链资产的价值。Enjin平台铸造的每个资产都包含ENJ, ENJ被锁定在内部并随着流通移除。",
  },

  WAVESUSDT: {
    name: "WAVES",
    code: "WAVES/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "05:55:00",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2016/04/12",
    publicTotal: "--",
    publicCurrent: "--",
    publicPrice: "--",
    shu: "https://docs.waves.tech/en/",
    publicWeb: "https://waves.tech/",
    publicSearch: "http://wavesexplorer.com/",
    dec: "\tWaves是一个面向Web3.0应用及分布解决方案的开放区块链协议和开发者工具集。Waves的愿景是通过提升IT系统的安全性，可靠性和速度，来支持开发者建立自己的链上应用，促进区块链产业的大规模落地。",
  },
  OPUSDT: {
    name: "OP",
    code: "OP/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2022/06/01",
    publicTotal: "4,294,967,296 OP",
    publicCurrent: "214,748,365 OP",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://app.optimism.io/announcement",
    publicSearch: "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000042",
    dec: "Optimism 是第一个开发与以太坊虚拟机 (EVM) 兼容的 Optimistic Rollup 解决方案的以太坊 L2。Optimism 与以太坊并行，能够在继承以太坊安全性的同时大规模处理交易，其主要特点是使以太坊链上交易成本降低。Optimism为以太坊开发人员提供了无需进行更改就能使用以太坊上所有可用工具的机会。其开发团队表示，交易员需要支付的矿工费将低10倍。此外，Optimism利用Optimism rollups解决了欺诈和无效状态转换问题。",
  },
  APTUSDT: {
    name: "APT",
    code: "APT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2022/10/19",
    publicTotal: "--",
    publicCurrent: "169,382,855 APT",
    publicPrice: "--",
    shu: "https://aptos.dev/aptos-white-paper/aptos-white-paper-index/",
    publicWeb: "https://aptoslabs.com/",
    publicSearch: "https://explorer.aptoslabs.com/",
    dec: "Aptos 是 Layer1 公链项目，其目标是建设一个可扩展、安全、可信任和可升级的智能合约平台。Aptos 团队从前Meta（前Facebook）独立出来，曾参与开发过稳定币项目 Libra（后改名为 Diem）。APT为Aptos主链的原生代币。",
  },
  ICPUSDT: {
    name: "ICP",
    code: "ICP/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2021/05/11",
    publicTotal: "469,213,710 ICP",
    publicCurrent: "291,211,036 ICP",
    publicPrice: "--",
    shu: "https://internetcomputer.org/whitepaper.pdf",
    publicWeb: "https://internetcomputer.org/",
    publicSearch: "https://dashboard.internetcomputer.org/",
    dec: "Internet Computer 协议是一个创新的、去中心化的区块链网络，旨在使区块链技术对公众开放。它寻求扩大智能合约的能力，并将公共互联网转变为全球云计算平台。 针对区块链技术最常见的批评之一是为了获得广泛采用，它需要更快、更方便。Internet Computer协议旨在通过使区块链功能以网速可用来解决这个问题。同时，确保交易在1秒内完成，并支付微量的GAS费用。它还为直接在公共互联网上部署智能合约代码提供了无障碍的开发人员环境。这简化了应用程序开发人员和用户的体验。 Internet Computer协议网络的架构旨在为独立的数据中心提供通信的灵活性，并提供一个完全去中心化的云计算平台。",
  },
  FTMUSDT: {
    name: "FTM",
    code: "FTM/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2018/06/15",
    publicTotal: "3,175,000,000 FTM",
    publicCurrent: "2,775,972,146 FTM",
    publicPrice: "--",
    shu: "https://resource.bnbstatic.com/books/20190528/1559080623602.pdf",
    publicWeb: "https://fantom.foundation/",
    publicSearch: "https://ftmscan.com/",
    dec: "Fantom是一个快速、高性能、开源的Layer-1 智能合约平台，托管多个数字资产和去中心化应用程序。FTM是基于ERC-20协议的原生代币。 Fantom的目标是解决区块链三难困境，它通过基于有向无环图(DAG)的模型讨论可伸缩性、安全性和去中心化之间的权衡。",
  },
  KLAYUSDT: {
    name: "KLAY",
    code: "KLAY/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2019/09/12",
    publicTotal: "--",
    publicCurrent: "3,073,851,606 KLAY",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://www.klaytn.foundation/",
    publicSearch: "https://scope.klaytn.com/blocks",
    dec: "Klaytn是以服务为中心的企业级分布式信任区块链平台。它通过高效的“混合”设计，结合了公共区块链（分散数据和控制，分布式治理）和私有区块链（低延迟，高可扩展性）的最优功能。Klaytn与全球众多知名品牌的参与合作，通过共同的不懈努力，创建可靠的去中心化业务平台。Klaytn会使当今的企业和企业家能够利用区块链技术获取更优厚的价值。",
  },
  GRTUSDT: {
    name: "GRT",
    code: "GRT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/12/13",
    publicTotal: "10,000,000,000 GRT",
    publicCurrent: "8,893,377,241 GRT",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://thegraph.com/en/",
    publicSearch: "https://www.oklink.com/zh-cn/eth/token/0xc944e90c64b2c07662a292be6244bdf05cda44a7",
    dec: "Graph是一个索引协议，它支持以太坊和IPFS等区块链应用程序以可验证的方式收集、处理和检索数据。该协议是开源的，使开发人员更容易构建和发布称为子图的开放API。GRT是其原生代币。 Graph使用Graph节点收集、分析和排序区块链数据。",
  },
  ARBUSDT: {
    name: "ARB",
    code: "ARB/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2023/03/23",
    publicTotal: "10,000,000,000 ARB",
    publicCurrent: "12.75亿 ARB",
    publicPrice: "--",
    shu: "https://github.com/OffchainLabs",
    publicWeb: "https://arbitrum.io/",
    publicSearch: "https://arbitrum.io/",
    dec: "Arbitrum 是以太坊的二层区块链，采用 optimistic rollups 扩展技术。 Arbitrum 区块链使用 rollup 将多项交易合并为一项，以降低链上交易成本并提高可扩展性。因此，Arbitrum 能够以比以太坊主网上低得多的矿工费实现每秒高达 40,000 笔交易的吞吐量。 Graph使用Graph节点收集、分析和排序区块链数据。",
  },
  SUIUSDT: {
    name: "SUI",
    code: "SUI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2023/05/03",
    publicTotal: "10,000,000,000 SUI",
    publicCurrent: "7.24亿 SUI",
    publicPrice: "--",
    shu: "https://github.com/MystenLabs",
    publicWeb: "https://sui.io/",
    publicSearch: "https://suiscan.xyz/mainnet/home",
    dec: "Sui 又称 Sui Network，是第一个从零开始设计，且能够使开发者为十亿 Web3 新用户构建全新体验的 Layer 1 区块链。Sui 可横向扩展，能够高速且低成本地支持多样的 DApp 开发。Sui 开创了通用区块链的先河，为用户带来高吞吐量、超快结算速度、丰富的链上资产、以及用户友好的 Web3 体验。Sui 是区块链技术的一个跨越式的进步，从底层开始设计，以满足加密货币中所有相关方的需求。",
  },
  PEPEUSDT: {
    name: "PEPE",
    code: "PEPE/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2023/04/14",
    publicTotal: "--",
    publicCurrent: "4,206,900.00亿 PEPE",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://www.pepe.vip/",
    publicSearch: "https://www.oklink.com/cn/eth/token/0x6982508145454Ce325dDbE47a25d4ec3d2311933",
    dec: "Pepe the Frog 是一个卡通人物和互联网 meme。Pepe 被设计成一个拥有人形身体的绿色拟人青蛙，是世界上最知名的 meme 之一。",
  },
  LDOUSDT: {
    name: "LDO",
    code: "LDO/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2020/12/17",
    publicTotal: "1,000,000,000",
    publicCurrent: "890,214,088 LDO",
    publicPrice: "--",
    shu: "https://lido.fi/static/Lido:Ethereum-Liquid-Staking.pdf",
    publicWeb: "https://lido.fi/",
    publicSearch: "https://etherscan.io/token/0x5a98fcbea516cf06857215779fd812ca3bef1b32",
    dec: "Lido Finance是一個抵押解決方案提供商，為Ethereum 2.0、Terra、Solana及Kusama提供服務。通過Lido進行抵押的用戶能夠保持其抵押代幣的控制權和流動性。LDO是Lido DAO的治理代幣。",
  },
  ALGOUSDT: {
    name: "ALGO",
    code: "ALGO/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00-05:50:00",
    sellTimeAM: "06:00:00-05:55:00",
    clearTime: "每日05:55 ",
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    max: "50000 人民币",
    min: "50 人民币",
    lever: "10x~100x",
    holdingTime: "永久",
    publicTime: "2019/06/20",
    publicTotal: "10,000,000,000",
    publicCurrent: "7,925,401,126",
    publicPrice: "--",
    shu: "https://algorand.com/technology/white-papers",
    publicWeb: "https://www.algorand.foundation/",
    publicSearch: "https://algoexplorer.io/",
    dec: "Algorand 是由麻省理工学院（MIT）教授、哥德尔奖（Gödel Prize）得主、图灵奖（Turing Award）得主 Silvio Micali 领导开发的一个区块链项目 。该项目成功募集到了一笔 400 万美元的种子轮融资，投资方包括知名风投 Pillar 和 Union Square Ventures。Algorand 没有引入激励机制或发行数字加密货币，项目本身具有广阔的市场空间，它通过 BA*的共识机制和密码抽签等技术创新，提高区块链的效率，拓展了区块链的应用前景，在硅谷享有极高的声誉。",
  },
};
